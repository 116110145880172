import statesCities from '~/assets/data/states-cities.js';
import supportedDestinationCountries from '~/assets/data/supportedDestinationCountries.js';
import supportedOriginCountries from '~/assets/data/supportedOriginCountries.js';

export const state = () => ({
  activeAddress: null,
  statesCitiesDataIsLoading: false,
  statesCitiesData: {},
  allStatesCitiesData: {},
  allStatesData: {},
  statesData: [],
  fetchingAddresses: false,
  addresses: [],
  supportedDestinationCountriesData: supportedDestinationCountries,
  supportedOriginCountriesData: supportedOriginCountries,
  fetchingPickupAddresses: false,
  pickupAddresses: [],
});

export const mutations = {
  setActiveAddress (state, address) {
    state.activeAddress = address || null;
  },
  setStatesCitiesDataIsLoading (state, isLoading) {
    state.statesCitiesDataIsLoading = isLoading;
  },
  setStatesCitiesData (state, statesCitiesObj) {
    state.statesCitiesData = statesCitiesObj;
    state.statesData = Object.keys(statesCitiesObj);
  },
  setAllStatesCitiesData (state, statesCitiesObj) {
    const [key, value] = Object.entries(statesCitiesObj)[0];
    state.allStatesCitiesData = { ...state.allStatesCitiesData, ...statesCitiesObj };
    state.allStatesData = { ...state.allStatesData, [key]: Object.keys(value) };
  },
  setAddresses (state, addresses) {
    state.addresses = addresses || [];
  },
  setFetchingAddresses (state, isLoading) {
    state.fetchingAddresses = isLoading;
  },
  setPickupAddresses (state, addresses) {
    state.pickupAddresses = addresses || [];
  },
  setFetchingPickupAddresses (state, isLoading) {
    state.fetchingPickupAddresses = isLoading;
  },
};

export const getters = {
  activeAddress: (state) => {
    return state.activeAddress;
  },
  statesCitiesDataIsLoading: (state) => {
    return state.statesCitiesDataIsLoading;
  },
  statesCitiesData: (state) => {
    return state.statesCitiesData;
  },
  allStatesCitiesData: (state) => {
    return state.allStatesCitiesData;
  },
  allStatesData: (state) => {
    return state.allStatesData;
  },
  statesData: (state) => {
    return state.statesData;
  },
  supportedDestinationCountriesData: (state) => {
    return state.supportedDestinationCountriesData;
  },
  supportedOriginCountriesData: (state) => {
    return state.supportedOriginCountriesData;
  },
  fetchingAddresses: (state) => {
    return state.fetchingAddresses;
  },
  addresses: (state) => {
    return state.addresses;
  },
  pickupAddresses: (state) => {
    return state.pickupAddresses;
  },
  fetchingPickupAddresses: (state) => {
    return state.fetchingPickupAddresses;
  },
};

export const actions = {
  setAddresses ({ commit }, addresses) {
    commit('setAddresses', addresses);
  },
  setActiveAddress ({ commit }, address) {
    commit('setActiveAddress', address);
  },
  async fetchStatesCities ({ commit }, params = {}) {
    commit('setStatesCitiesDataIsLoading', true);
    this.dispatch('notification/clearNotification');

    try {
      const { data } = await this.$api.delivery.addressBook.getStatesCities({ params });
      if (params.country) {
        commit('setAllStatesCitiesData', { [params.country]: data?.payload?.states });
      }
      commit('setStatesCitiesData', data?.payload?.states || {});
    } catch (error) {
      const { states } = statesCities || {};
      commit('setStatesCitiesData', states || {});

      if (error) {
        const { data = {}, status } = error;
        const { msg } = data;
        if (msg && status && this._vm) {
          this._vm.handleStoreServerError({ msg, status }, this);
        }
      }
    } finally {
      commit('setStatesCitiesDataIsLoading', false);
    }
  },
  async fetchAddresses ({ commit }, params = {}) {
    commit('setFetchingAddresses', true);
    this.dispatch('notification/clearNotification');
    try {
      const { data } = await this.$api.delivery.addressBook.list({ params });
      commit('setAddresses', data?.payload?.addresses || []);
    } catch (error) {
      if (error) {
        const { data = {}, status } = error;
        const { msg } = data;
        if (msg && status && this._vm) {
          this._vm.handleStoreServerError({ msg, status }, this);
        }
      }
    } finally {
      commit('setFetchingAddresses', false);
    }
  },
  async fetchPickupAddresses ({ commit }, params = {}) {
    commit('setFetchingPickupAddresses', true);
    this.dispatch('notification/clearNotification');
    try {
      const { data } = await this.$api.delivery.pickups.listPickupLocations({ params });
      commit('setPickupAddresses', data?.payload?.locations || []);
    } catch (error) {
      if (error) {
        const { data = {}, status } = error;
        const { msg } = data;
        if (msg && status && this._vm) {
          this._vm.handleStoreServerError({ msg, status }, this);
        }
      }
    } finally {
      commit('setFetchingPickupAddresses', false);
    }
  },
};
