const fetchCommonData = async ({ store, route }) => {
  const commonKeyToUrlsMap = store.getters['common/commonKeyToUrlMap'];
  const commonDataPromises = Object.entries(commonKeyToUrlsMap).reduce((promises, entry) => {
    const [commonKey, commonUrl] = entry || [];
    if (route.path.startsWith(commonUrl)) {
      const commonData = store.getters['common/commonData'] || {};
      const currentCommonData = commonData[commonKey] || {};
      if (!currentCommonData.published_at) {
        return [
          ...promises,
          store.dispatch('common/fetchCommonData', commonKey),
        ];
      }
    }
    return promises;
  }, []);

  const ngWarehouseAddressData = store.getters['contact/ngWarehouseAddressData'];
  if (ngWarehouseAddressData.length < 1) {
    commonDataPromises.push(store.dispatch('contact/fetchBusinessLocations'));
  }

  const ngContactNumbersData = store.getters['contact/ngContactNumbersData'];
  if (ngContactNumbersData.length < 1) {
    commonDataPromises.push(store.dispatch('contact/fetchContactNumbers'));
  }

  try {
    await Promise.all(commonDataPromises);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export default function (params) {
  return fetchCommonData(params);
}
