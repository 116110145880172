const getServiceBasePath = version => `/delivery/${version}`;

export default ($axios, $apiVersion = 'v1') => ({
  addressBook: {
    getStatesCities: ({ headers, params }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/addresses/states-cities`;
      return $axios.get(urlPath, { headers, params });
    },
    estimateDeliveryFee: ({ headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/public/addresses/estimate-delivery-fee`;
      return $axios.post(urlPath, data, { headers });
    },
  },
  pickups: {
    listPickupLocations: ({ headers, params }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/pickups/locations`;
      return $axios.get(urlPath, { headers, params });
    },
  },
});
