let source;
export const axiosOnce = $axios => ({
  get (url, config) {
    return this.call({
      url,
      method: 'get',
      ...config
    });
  },
  call (config = {}) {
    if (source) {
      source.cancel();
    }
    source = $axios.CancelToken.source();

    config.cancelToken = source.token;
    return $axios(config);
  }
});

export default $axios => ({
  shipments: {
    track: (trackingNo, options) => $axios(`/charis/shipments/public/tracking/${trackingNo}`, options),
  },
  fetchSettings: options => $axios.get('/charis/public/settings', options),
});
