const getServiceBasePath = version => `/ordering/${version}`;

export default ($axios, $apiVersion = 'v1') => ({
  orders: {
    estimate: ({ headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/public/orders/estimate`;
      return $axios.post(urlPath, data, { headers });
    },
  },
});
