const getServiceBasePath = version => `/subscription/${version}`;

export default ($axios, $apiVersion = 'v1') => ({
  plans: {
    list: ({ params }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/public/plans`;
      return $axios.get(urlPath, params);
    },
  }
});
