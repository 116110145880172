export const state = () => ({});

export const mutations = {};

export const getters = {};

export const actions = {
  async nuxtServerInit ({ commit }, { req }) {
    if (req?.headers?.cookie) {
      const parsed = this.$cookies.getAll();
      const { country, promotions } = parsed;
      if (req && req.headers) {
        const reqHeaders = await req.headers;
        const cfIpCountryKey = 'cf-ipcountry';
        const pageCountry = country || reqHeaders[cfIpCountryKey];
        commit('page/setPageCountry', pageCountry);
      }
      if (promotions) {
        commit('promotion/setPromotions', promotions);
      }
    }
  },
};
