const supportedDestinationCountries = {
  GH: {
    alpha2: 'GH',
    alpha3: 'GHA',
    countryCallingCodes: ['+233'],
    currencies: ['GHS'],
    emoji: '🇬🇭',
    ioc: 'GHA',
    languages: ['eng'],
    name: 'Ghana',
    flagIconUrl: '/svg/flags/icon-flag-gh.svg',
  },
  NG: {
    alpha2: 'NG',
    alpha3: 'NGA',
    countryCallingCodes: ['+234'],
    currencies: ['NGN'],
    emoji: '🇳🇬',
    ioc: 'NGR',
    languages: ['eng'],
    name: 'Nigeria',
    flagIconUrl: '/svg/flags/icon-flag-ng.svg',
  },
};

export default supportedDestinationCountries;
