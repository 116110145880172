export const state = () => ({
  featureFlagConstants: {},
});

export const getters = {
  getUserFlags: (state) => {
    return state.userFlags;
  },
  getFFConstants: (state) => {
    return state.featureFlagConstants;
  }
};
