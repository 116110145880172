import * as LDClient from 'launchdarkly-js-client-sdk';

export default (ctx, inject) => {
  const context = {
    kind: 'service',
    key: 'stela',
  };
  const client = LDClient.initialize(ctx.$config.LdClientId, context);
  inject('ldClient', client);
};
