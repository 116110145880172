import Vue from 'vue';

import GAuth from 'vue-google-oauth2';

export default (ctx) => {
  if (ctx.$config.AppEnv === 'production') {
    const gauthOption = {
      clientId: ctx.$config.GoogleClientId,
      scope: 'profile email',
      prompt: 'select_account'
    };

    Vue.use(GAuth, gauthOption);
  }
};
