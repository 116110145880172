export const state = () => ({
  plansDataIsLoading: false,
  plansData: [],
});

export const mutations = {
  setPlansDataIsLoading (state, isLoadingVal) {
    state.plansDataIsLoading = isLoadingVal;
  },
  setPlansData (state, plans) {
    state.plansData = plans;
  },
};

export const getters = {
  plansDataIsLoading: (state) => {
    return state.plansDataIsLoading;
  },
  plansData: (state) => {
    return state.plansData;
  },
};

export const actions = {
  async fetchPlans ({ commit }, params) {
    this.commit('plan/setPlansDataIsLoading', true);
    this.dispatch('notification/clearNotification');

    if (typeof params === 'object' && 's' in params) {
      params.query = params.s;
      delete params.s;
    }

    try {
      const { data } = await this.$api.subscription.plans.list({ params });
      this.commit('plan/setPlansData', data.payload.plans || []);
      this.commit('pagination/setPaginationData', data.payload.meta || {});
    } catch (error) {
      const { data = {}, status } = error.response || error || {};
      const { msg } = data;
      if (msg && status && this._vm) {
        this._vm.handleStoreServerError({ msg, status }, this);
      }
    } finally {
      this.commit('plan/setPlansDataIsLoading', false);
    }
  },
};
