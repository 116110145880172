import createConfigService from './api/config.js';
import createShippingService from './api/shipping.js';
import createOrderingService from './api/ordering.js';
import createSubscriptionService from './api/subscription.js';
import createDeliveryService from './api/delivery.js';

export default (ctx, inject) => {
  // inject service apis as this.$api
  inject('api', {
    config: createConfigService(ctx.$axios),
    shipping: createShippingService(ctx.$axios),
    ordering: createOrderingService(ctx.$axios),
    subscription: createSubscriptionService(ctx.$axios),
    delivery: createDeliveryService(ctx.$axios),
  });
};
