
import { mapGetters, mapActions } from 'vuex';
import SlideTransition from '~/components/transitions/SlideTransition';
import ResourcesNavContentMobile from '~/components/resources/ResourcesNavContentMobile';
import SolutionsNavContentMobile from '~/components/solutions/SolutionsNavContentMobile';
import getHeaderData from '~/assets/data/get-header-data';
import { logoMixin } from '~/mixins';

export default {
  name: 'StelaSideNavigations',
  components: {
    SlideTransition,
    ResourcesNavContentMobile,
    SolutionsNavContentMobile,
  },
  mixins: [logoMixin],
  props: {
    pageTitle: {
      type: String,
      default: null,
    },
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
    showMainMenu: {
      type: Boolean,
      default: true,
    },
    closeIcon: {
      type: String,
      default: '/svg/icon-close.svg',
    },
  },
  data () {
    return {
      sideNavigationsMenuIsOpen: '',
    };
  },
  computed: {
    commonKey () {
      return 'header-data';
    },
    headerData () {
      return this.allCommonData[this.commonKey] || {};
    },
    defaultHeaderData () {
      const dataObj = {
        CUSTOMER_REVIEW_URL: this.CUSTOMER_REVIEW_URL,
        BLOG_URL: this.BLOG_URL,
      };

      return getHeaderData(dataObj);
    },
    mainHeaderLinks () {
      return this.headerData?.mainHeaderLinks || this.defaultHeaderData.mainHeaderLinks;
    },
    ...mapGetters({
      allCommonData: 'common/commonData',
      showPageSidebar: 'page/showPageSidebar',
    }),
  },
  methods: {
    hasContent (content) {
      return content.length > 0;
    },
    toggleSideNavigationsMenu (title) {
      if (this.sideNavigationsMenuIsOpen === title) {
        this.sideNavigationsMenuIsOpen = '';
        return;
      }
      this.sideNavigationsMenuIsOpen = title;
    },
    getActiveClass (navLink) {
      return this.$route.path.toLowerCase().includes(navLink.toLowerCase())
        ? 'active'
        : '';
    },
    ...mapActions({
      togglePageSidebar: 'page/togglePageSidebar',
    }),
  },
};
