import fetch from 'node-fetch';
import qs from 'qs';

// returns json response if successful
const handleFetchResponse = (response) => {
  if (response.ok) {
    return response.json();
  }
};

const fetchTera = (url, options) => {
  if (options && options.query && Object.keys(options.query).length > 0) {
    const query = qs.stringify(options.query);
    url = query ? `${url}?${query}` : url;
    delete options.query;
  }
  return fetch(url, options).then(handleFetchResponse);
};

export default teraUrl => ({
  collections: {
    'business-locations': options => fetchTera(`${teraUrl}/business-locations`, options),
    'contact-numbers': options => fetchTera(`${teraUrl}/contact-numbers`, options),
    'solution-pages': (slug, options) => fetchTera(`${teraUrl}/solution-pages/${slug}`, options),
  },
  pages: {
    'home-page': options => fetchTera(`${teraUrl}/home-page`, options),
    'solutions-page': options => fetchTera(`${teraUrl}/solutions-page`, options),
    'about-page': options => fetchTera(`${teraUrl}/about-page`, options),
    'pricing-page': options => fetchTera(`${teraUrl}/pricing-page`, options),
    'legal-page': options => fetchTera(`${teraUrl}/legal-page`, options),
    'founder-story-page': options => fetchTera(`${teraUrl}/founder-story-page`, options),
    'contact-page': options => fetchTera(`${teraUrl}/contact-page`, options),
    'report-fraud-page': options => fetchTera(`${teraUrl}/report-fraud-page`, options),
    'help-center-page': options => fetchTera(`${teraUrl}/help-center-page`, options),
    'getting-started-page': options => fetchTera(`${teraUrl}/getting-started-page`, options),
    'faq-page': options => fetchTera(`${teraUrl}/faq-page`, options),
    'ocean-shipping-page': options => fetchTera(`${teraUrl}/ocean-shipping-page`, options),
    'shipping-guideline-page': options => fetchTera(`${teraUrl}/shipping-guideline-page`, options),
    'video-resources-page': options => fetchTera(`${teraUrl}/video-resources-page`, options),
  },
  commons: {
    'header-data': options => fetchTera(`${teraUrl}/header-data`, options),
    'notification-banner': options => fetchTera(`${teraUrl}/notification-banner`, options),
    'footer-data': options => fetchTera(`${teraUrl}/footer-data`, options),
  },
  email: {
    send: options => fetchTera(`${teraUrl}/email`, options),
  },
  helpCenterPageSearch: search => fetchTera(`${teraUrl}/help-center-page/search?s=${search}`),
  fetchRecentlyPublishedBlogPosts: options => fetchTera(`${teraUrl}/blog-posts?_sort=published_at:desc&_limit=3`, options),
});
