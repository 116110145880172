import LOCALE, { HOME_COUNTRY } from '~/assets/constants/LOCALE_DESTINATION_COUNTRY';
// import FEATURE_FLAG from '~/assets/constants/FEATURE_FLAG.js';

const fetchPageData = async ({ store, route, redirect }) => {
  let countryCode = '';
  await LOCALE.COUNTRIES.forEach((c) => {
    const regexp = new RegExp(`^/${c.alpha2.toLowerCase()}`);
    if (route.path.match(regexp)) {
      countryCode = c.alpha2;
    }
  });

  const pageCountry = store.getters['page/pageCountry'];

  if (countryCode === '' && pageCountry && pageCountry !== HOME_COUNTRY.alpha2) {
    const redirectTo = route.path === '/'
      ? `/${pageCountry.toLowerCase()}`
      : `/${pageCountry.toLowerCase()}${route.fullPath}`;
    return redirect(redirectTo);
  } else if (countryCode && pageCountry === HOME_COUNTRY.alpha2) {
    const redirectTo = `/${route.fullPath.split('/').slice(2).join('/')}`;
    return redirect(redirectTo);
  }

  const pageKeyData = store.getters['page/pageKeyData'];
  const pageDataPromises = Object.entries(pageKeyData).reduce((promises, entry) => {
    const [pageKey, pageData] = entry || [];
    if (
      route.path.startsWith(pageData.url) ||
      route.path.startsWith(`/${countryCode.toLowerCase()}${pageData.url}`)
    ) {
      const pageData = store.getters['page/pageData'] || {};
      const currentPageData = pageData[pageKey] || {};
      if (!currentPageData.published_at) {
        return [
          ...promises,
          store.dispatch('page/fetchPageData', pageKey),
        ];
      }
    }
    return promises;
  }, []);

  try {
    await Promise.all(pageDataPromises);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export default function (params) {
  return fetchPageData(params);
}
