
export default {
  name: 'StelaLink',
  props: {
    text: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
  }
};
