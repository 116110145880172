import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { startCase } from 'lodash';
import DELIVERY_CHOICE from '~/assets/constants/DELIVERY_CHOICE';
import SHIPMENT_STATUS from '~/assets/constants/SHIPMENT_STATUS';
import API_SETTINGS_CONFIG_KEY from '~/assets/constants/API_SETTINGS_CONFIG_KEY';
import LOCALE from '~/assets/constants/LOCALE_DESTINATION_COUNTRY';
import FEATURE_FLAG from '~/assets/constants/FEATURE_FLAG';

Vue.mixin({
  data () {
    return {
      USD_TO_NAIRA_RATE: 500,
      CONTACT_EMAIL: 'helpdesk@heroshe.com',
      BLOG_URL: 'https://blog.heroshe.com',
      LOGIN_URL: 'https://post.heroshe.com/auth/login',
      SIGNUP_URL: 'https://post.heroshe.com/auth/signup',
      CUSTOM_QUOTE_URL:
      'https://form.typeform.com/to/e4234ZxE',
      DASHBOARD_URL: 'https://post.heroshe.com/dashboard',
      CUSTOMER_REVIEW_URL: 'https://g.page/shipheroshe/review',
      GHANA_LAUNCH_BLOG_URL:
        'https://blog.heroshe.com/hello-ghana-heroshe-is-here',
      UK_LAUNCH_BLOG_URL:
        'https://blog.heroshe.com/everything-you-need-to-know-about-shipping-from-the-uk-to-nigeria-and-ghana',
      CH_LAUNCH_BLOG_URL:
        'https://blog.heroshe.com/shipping-from-china-to-nigeria/',
      OCEAN_SHIPPING_BLOG_URL:
        'https://blog.heroshe.com/how-ocean-shipping-saves-you-money/',
      PRICING_FEE_BLOG_URL:
        'https://blog.heroshe.com/heroshe-new-pricing-model/',
      DELIVERY_CHOICE_PICKUP: DELIVERY_CHOICE.PICKUP.value,
      DELIVERY_CHOICE_HOME: DELIVERY_CHOICE.HOME.value,
      DELIVERY_CHOICE_HOME_LAGOS: DELIVERY_CHOICE.HOME.subtypes.HOME_LAGOS,
      DELIVERY_CHOICE_HOME_NON_LAGOS:
        DELIVERY_CHOICE.HOME.subtypes.HOME_NON_LAGOS,
      SHIPMENT_STATUS_EXPECTED: SHIPMENT_STATUS.EXPECTED,
      SHIPMENT_STATUS_RECEIVED: SHIPMENT_STATUS.RECEIVED,
      SHIPMENT_STATUS_PROCESSED: SHIPMENT_STATUS.PROCESSED,
      SHIPMENT_STATUS_PACKED: SHIPMENT_STATUS.PACKED,
      SHIPMENT_STATUS_DROPPED_OFF: SHIPMENT_STATUS['DROPPED-OFF'],
      SHIPMENT_STATUS_SHIPPED: SHIPMENT_STATUS.SHIPPED,
      SHIPMENT_STATUS_SORTED: SHIPMENT_STATUS.SORTED,
      SHIPMENT_STATUS_PICKUP: SHIPMENT_STATUS.PICKUP,
      SHIPMENT_STATUS_PICKED_UP: SHIPMENT_STATUS['PICKED-UP'],
      SHIPMENT_STATUS_DISPATCHED: SHIPMENT_STATUS.DISPATCHED,
      SHIPMENT_STATUS_DELIVERED: SHIPMENT_STATUS.DELIVERED,
    };
  },
  computed: {
    LD_FF_STATE () {
      if (this.$ldClient) {
        return this.$ldClient?.allFlags();
      }
      return {};
    },
    featureFlaggedDestinationCountries () {
      const countries = [];
      if (FEATURE_FLAG?.GHANA_OPERATIONS && !this.LD_FF_STATE[FEATURE_FLAG.GHANA_OPERATIONS]) {
        countries.push(LOCALE.COUNTRY.GH.alpha2);
      }

      return countries;
    },
    featureFlaggedOriginCountries () {
      const countries = [];
      return countries;
    },

    usAddress () {
      return this.usWarehouseAddressData[0] || {};
    },
    ukAddress () {
      return this.ukWarehouseAddressData[0] || {};
    },
    ngAddress () {
      return this.ngWarehouseAddressData[0] || {};
    },
    cnAddress () {
      return this.cnWarehouseAddressData[0] || {};
    },
    ghAddress () {
      return this.ghWarehouseAddressData[0] || {};
    },
    usOfficeAddress () {
      return this.getOfficeAddress(this.usAddress);
    },
    ukOfficeAddress () {
      return this.getOfficeAddress(this.ukAddress);
    },
    ngOfficeAddress () {
      return this.getOfficeAddress(this.ngAddress);
    },
    ghOfficeAddress () {
      return this.getOfficeAddress(this.ghAddress);
    },
    cnOfficeAddress () {
      return this.getOfficeAddress(this.cnAddress);
    },
    officeAddress () {
      if (this.pageLocale === LOCALE.COUNTRY.GH.locale) {
        return this.ghOfficeAddress;
      }
      return this.ngOfficeAddress;
    },
    usOfficePhoneNumbers () {
      return this.getOfficePhoneNumbers(this.usContactNumbersData);
    },
    ukOfficePhoneNumbers () {
      return this.getOfficePhoneNumbers(this.ukContactNumbersData);
    },
    ngOfficePhoneNumbers () {
      return this.getOfficePhoneNumbers(this.ngContactNumbersData);
    },
    ghOfficePhoneNumbers () {
      return this.getOfficePhoneNumbers(this.ghContactNumbersData);
    },
    cnOfficePhoneNumbers () {
      return this.getOfficePhoneNumbers(this.cnContactNumbersData);
    },
    officePhoneNumbers () {
      if (this.pageLocale === LOCALE.COUNTRY.GH.locale) {
        return this.ghOfficePhoneNumbers;
      }
      return this.ngOfficePhoneNumbers;
    },
    usContactNumber1 () {
      return this.usContactNumbersData[0]
        ? this.usContactNumbersData[0].phone_number
        : '';
    },
    ukContactNumber1 () {
      return this.ukContactNumbersData[0]
        ? this.ukContactNumbersData[0].phone_number
        : '';
    },
    ngContactNumber1 () {
      return this.ngContactNumbersData[0]
        ? this.ngContactNumbersData[0].phone_number
        : '';
    },
    ghContactNumber1 () {
      return this.ghContactNumbersData[0]
        ? this.ghContactNumbersData[0].phone_number
        : '';
    },
    cnContactNumber1 () {
      return this.cnContactNumbersData[0]
        ? this.cnContactNumbersData[0].phone_number
        : '';
    },
    shipmentStatuses () {
      return [
        this.SHIPMENT_STATUS_RECEIVED,
        this.SHIPMENT_STATUS_PROCESSED,
        this.SHIPMENT_STATUS_PACKED,
        this.SHIPMENT_STATUS_DROPPED_OFF,
        this.SHIPMENT_STATUS_SHIPPED,
        this.SHIPMENT_STATUS_SORTED,
        this.SHIPMENT_STATUS_DISPATCHED,
        this.SHIPMENT_STATUS_DELIVERED,
      ];
    },
    shipmentStatusTextMap () {
      return {
        [this.SHIPMENT_STATUS_RECEIVED]: 'Received at the warehouse',
        [this.SHIPMENT_STATUS_PROCESSED]: 'Processed',
        [this.SHIPMENT_STATUS_PACKED]: 'Packing',
        [this.SHIPMENT_STATUS_DROPPED_OFF]: 'Dropped off to airport',
        [this.SHIPMENT_STATUS_SHIPPED]: "On it's way",
        [this.SHIPMENT_STATUS_SORTED]: 'Arrived Lagos, Nigeria',
        [this.SHIPMENT_STATUS_PICKUP]: 'Available for pickup',
        [this.SHIPMENT_STATUS_PICKED_UP]: 'Picked up',
        [this.SHIPMENT_STATUS_DISPATCHED]: 'Dispatched for delivery',
        [this.SHIPMENT_STATUS_DELIVERED]: 'Delivered',
      };
    },
    shipmentStatusInfoMap () {
      return {
        [this.SHIPMENT_STATUS_RECEIVED]: 'Received at the warehouse',
        [this.SHIPMENT_STATUS_PROCESSED]:
          'Package has been received and processed at the warehouse',
        [this.SHIPMENT_STATUS_PACKED]:
          'Shipment has been paid for and ready for outbound ship out',
        [this.SHIPMENT_STATUS_DROPPED_OFF]:
          'Shipment has been scanned into a cargo manifest and is pending flight takeoff',
        [this.SHIPMENT_STATUS_SHIPPED]:
          'Shipment has been shipped and headed to Lagos, Nigeria',
        [`${this.SHIPMENT_STATUS_SORTED}${this.DELIVERY_CHOICE_PICKUP}`]:
          'Shipment has arrived Lagos and is ready for pickup',
        [`${this.SHIPMENT_STATUS_SORTED}${this.DELIVERY_CHOICE_HOME}`]:
          'Shipment has arrived Lagos and is undergoing sorting and will be dispatched soon',
        [this.SHIPMENT_STATUS_PICKUP]:
          'Shipment has arrived Lagos and is ready for pickup',
        [this.SHIPMENT_STATUS_PICKED_UP]: 'Shipment has been picked up',
        [this.SHIPMENT_STATUS_DISPATCHED]:
          'Shipment has been dispatched for delivery',
        [this.SHIPMENT_STATUS_DELIVERED]: 'Shipment has been delivered',
      };
    },
    shippingRateCurrency () {
      return this.API_SETTINGS.pricePerPound.currency;
    },
    shippingRateDisplay () {
      return this.API_SETTINGS.pricePerPound.display;
    },
    displayedShippingRate () {
      let currencySymbol = '';
      if (this.shippingRateCurrency === 'NGN') {
        currencySymbol = '₦';
      }
      if (this.shippingRateCurrency === 'USD') {
        currencySymbol = '$';
      }
      return `${currencySymbol}${this.shippingRateDisplay}`;
    },
    shippingRate () {
      return this.API_SETTINGS.pricePerPound;
    },
    API_SETTINGS () {
      const deliveryRateForLagosNonXL =
        this.configKeyToValueMap[
          API_SETTINGS_CONFIG_KEY.deliveryFeeRates.lagosNonXL
        ];
      const deliveryRateForLagosXL =
        this.configKeyToValueMap[
          API_SETTINGS_CONFIG_KEY.deliveryFeeRates.lagosXL
        ];
      const deliveryRateForNonLagosNonXL =
        this.configKeyToValueMap[
          API_SETTINGS_CONFIG_KEY.deliveryFeeRates.nonLagosNonXL
        ];
      const deliveryRateForNonLagosXL =
        this.configKeyToValueMap[
          API_SETTINGS_CONFIG_KEY.deliveryFeeRates.nonLagosXL
        ];
      const deliveryRateForGhana =
        this.configKeyToValueMap[
          API_SETTINGS_CONFIG_KEY.deliveryFeeRates.ghana
        ];
      const exchangeRate =
        this.configKeyToValueMap[API_SETTINGS_CONFIG_KEY.exchangeRate];
      const pricePerPound =
        this.configKeyToValueMap[API_SETTINGS_CONFIG_KEY.pricePerPound];

      return {
        deliveryFeeRates: {
          lagosNonXL: this.createAmountObj(
            Number(deliveryRateForLagosNonXL) || 0
          ),
          lagosXL: this.createAmountObj(Number(deliveryRateForLagosXL) || 0),
          nonLagosNonXL: this.createAmountObj(
            Number(deliveryRateForNonLagosNonXL) || 0
          ),
          nonLagosXL: this.createAmountObj(
            Number(deliveryRateForNonLagosXL) || 0
          ),
          ghana: this.createAmountObj(Number(deliveryRateForGhana) || 0),
        },
        exchangeRate: Number(exchangeRate) || 0,
        pricePerPound: this.createAmountObj(Number(pricePerPound) || 0),
      };
    },
    EXCHANGE_RATE () {
      return this.API_SETTINGS.exchangeRate;
    },
    PRICE_PER_POUND () {
      return this.shippingRate?.charge / 100;
    },
    ...mapGetters({
      ghContactNumbersData: 'contact/ghContactNumbersData',
      usWarehouseAddressData: 'contact/usWarehouseAddressData',
      ukWarehouseAddressData: 'contact/ukWarehouseAddressData',
      usContactNumbersData: 'contact/usContactNumbersData',
      ukContactNumbersData: 'contact/ukContactNumbersData',
      ghWarehouseAddressData: 'contact/ghWarehouseAddressData',
      ngWarehouseAddressData: 'contact/ngWarehouseAddressData',
      cnWarehouseAddressData: 'contact/cnWarehouseAddressData',
      ngContactNumbersData: 'contact/ngContactNumbersData',
      cnContactNumbersData: 'contact/cnContactNumbersData',
      featureFlagConstants: 'auth/getFFConstants',
      configEntries: 'config/configEntries',
      configKeyToValueMap: 'config/configKeyToValueMap',
      pageCountry: 'page/pageCountry',
      pageLocale: 'page/pageLocale',
    }),
  },
  mounted () {},
  methods: {
    mapSelectOption (x) {
      return { label: x, value: x };
    },
    getInputClassName (inputClassName = 'form-input', input) {
      // Appends is-invalid class to form input class if it is invalid
      if (!this.$v[input]) {
        return inputClassName;
      }
      return inputClassName + (this.$v[input].$error ? ' is-invalid' : '');
    },
    excludeFlaggedCountries (countries = [], flaggedCountries = []) {
      return countries.filter(
        country => !flaggedCountries.includes(country.alpha2)
      );
    },
    getOfficeAddress (businessLocation) {
      let officeAddress = '';
      const {
        address_line_1: addressLine1 = '',
        address_line_2: addressLine2 = '',
        city = '',
        state = '',
      } = businessLocation || {};
      if (addressLine1 && addressLine2 && city && state) {
        officeAddress = `${addressLine2}, ${addressLine1}, \n${city}, ${state}`;
      } else if (addressLine1 && city && state) {
        officeAddress = `${addressLine1}, \n${city}, ${state}`;
      }
      return officeAddress;
    },
    getOfficePhoneNumbers (contactNumbersData) {
      return contactNumbersData.reduce((phoneNumbers, x, index) => {
        const { phone_number: phone } = x || {};
        if (index === 0) {
          return phone || '';
        }
        return `${phoneNumbers}, \n${phone || ''}`;
      }, '');
    },
    isServerError (status) {
      return [500, 501, 502, 503].includes(status);
    },
    isEnvironment (environment) {
      return this.$config.AppEnv === environment;
    },
    back (url) {
      if (url) {
        this.$router.push(url);
      } else if (window.history.length > 2) {
        this.$router.back();
      } else {
        this.$router.push(this.$route.path.split('/').slice(0, -1).join('/'));
      }
    },
    dropdownShowUpwards (index, rowLength) {
      return index > rowLength / 2 && rowLength > 6;
    },
    removeHash () {
      // NB: We can also do => history.replaceState(null, null, ' ')
      let scrollV;
      let scrollH;
      const loc = window.location;
      if ('pushState' in history) {
        history.pushState('', document.title, loc.pathname + loc.search);
      } else {
        // Prevent scrolling by storing the page's current scroll offset
        scrollV = document.body.scrollTop;
        scrollH = document.body.scrollLeft;
        loc.hash = '';
        // Restore the scroll offset, should be flicker free
        document.body.scrollTop = scrollV;
        document.body.scrollLeft = scrollH;
      }
    },
    objectToQueryString (initialObj) {
      const reducer =
        (obj, parentPrefix = null) =>
          (prev, key) => {
            const val = obj[key];
            key = encodeURIComponent(key);
            const prefix = parentPrefix ? `${parentPrefix}[${key}]` : key;

            if (val == null || typeof val === 'function') {
              prev.push(`${prefix}=`);
              return prev;
            }

            if (['number', 'boolean', 'string'].includes(typeof val)) {
              prev.push(`${prefix}=${encodeURIComponent(val)}`);
              return prev;
            }

            prev.push(
              Object.keys(val).reduce(reducer(val, prefix), []).join('&')
            );
            return prev;
          };

      return Object.keys(initialObj).reduce(reducer(initialObj), []).join('&');
    },
    shortName (name) {
      if (!name) {
        return '';
      }
      if (name.split(' ').length > 1) {
        return name.split(' ')[0];
      }
      if (name.length > 12) {
        return name.substr(0, 12) + '..';
      }
      return name;
    },
    convertToSentenceCase (str) {
      return startCase(str);
    },
    toFixed (num, fixed) {
      // eslint-disable-next-line no-useless-escape
      const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
      return num.toString().match(re)[0];
    },
    titleCase (str) {
      return str
        .toLowerCase()
        .split(' ')
        .map((word) => {
          return word.replace(word[0], word[0].toUpperCase());
        })
        .join(' ');
    },
    extractNum (str) {
      const strMatch = str.match(/\d+/g);
      if (!strMatch) {
        return '';
      }
      return strMatch.join('');
    },
    cleanupPhoneFormat (data) {
      const prefixes = ['234', '0', '1'];

      const prefix = new RegExp('^(' + prefixes.join('|') + ')', 'g');
      return data.replace(prefix, '');
    },
    numberRangeTo (size, startAt = 0) {
      return [...Array(size).keys()].map(i => i + startAt);
    },
    numberRange (start, end, step = 1) {
      const len = Math.floor((end - start) / step) + 1;
      return Array(len)
        .fill()
        .map((_, idx) => start + idx * step);
    },
    isEqual (value, other) {
      // Get the value type
      const type = Object.prototype.toString.call(value);

      // If the two objects are not the same type, return false
      if (type !== Object.prototype.toString.call(other)) {
        return false;
      }

      // If items are not an object or array, return false
      if (!['[object Array]', '[object Object]'].includes(type)) {
        return false;
      }

      // Compare the length of the length of the two items
      const valueLen =
        type === '[object Array]' ? value.length : Object.keys(value).length;
      const otherLen =
        type === '[object Array]' ? other.length : Object.keys(other).length;
      if (valueLen !== otherLen) {
        return false;
      }

      // Compare two items
      const compare = (item1, item2) => {
        // Get the object type
        const itemType = Object.prototype.toString.call(item1);

        // If an object or array, compare recursively
        if (['[object Array]', '[object Object]'].includes(itemType)) {
          if (!this.isEqual(item1, item2)) {
            return false;
          }
        } else {
          // Otherwise, do a simple comparison
          // If the two items are not the same type, return false
          if (itemType !== Object.prototype.toString.call(item2)) {
            return false;
          }

          // Else if it's a function, convert to a string and compare
          // Otherwise, just compare
          if (itemType === '[object Function]') {
            if (item1.toString() !== item2.toString()) {
              return false;
            }
          } else if (item1 !== item2) {
            return false;
          }
        }
      };

      // Compare properties
      if (type === '[object Array]') {
        for (let i = 0; i < valueLen; i++) {
          if (compare(value[i], other[i]) === false) {
            return false;
          }
        }
      } else {
        for (const key in value) {
          if (Object.prototype.hasOwnProperty.call(value, key)) {
            if (compare(value[key], other[key]) === false) {
              return false;
            }
          }
        }
      }

      // If nothing failed, return true
      return true;
    },
    isExternalUrl (url) {
      if (!url) {
        return false;
      }
      return url.startsWith('http');
    },
    parseStaticUrl (path = '/') {
      if (this.$config.staticPath && !this.isExternalUrl(path)) {
        return `${this.$config.staticPath}${path}`;
      }
      return path;
    },
    gotoUrl (url, target) {
      if (this.isExternalUrl(url) || target) {
        return window.open(url, target || '_blank');
      }
      this.$router.push(url);
    },
    formatCurrency (amount, currencyCode = '') {
      if (currencyCode) {
        const NumberFormat = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: currencyCode,
        });
        return NumberFormat.format(amount || 0);
      }
      return new Intl.NumberFormat().format(amount || 0);
    },
    getFormattedDateTimeZone (datetime, withTime = true) {
      if (!datetime) {
        return datetime;
      }
      const isoDateTime = this.$dayjs(datetime);
      const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const dateFormat = withTime ? 'lll' : 'll';
      return isoDateTime.tz(tzid).format(dateFormat) || '';
    },
    makeSlug (string) {
      if (!string) {
        return '';
      }
      string = string.replace(/[^\w\s]/gi, '');
      return string.trim().toLowerCase().split(' ').join('-');
    },
    openHelp () {
      if (window.fcWidget) {
        window.fcWidget.open();
        window.fcWidget.show();
        return;
      }
      if (window.$crisp) {
        window.$crisp.push(['do', 'chat:open']);
      }
    },
    getUtmParamAsString (utmParam) {
      if (Array.isArray(utmParam) && utmParam.length() > 0) {
        return JSON.stringify(utmParam);
      } else if (utmParam !== '') {
        return utmParam;
      }

      return null;
    },
    getChargeTotal (amount) {
      amount = String(amount).replaceAll(',', '');
      return Number(amount) * 100;
    },
    getDisplayTotal (amountInUnits = 0) {
      const amount = Number(amountInUnits / 100).toFixed(2);
      return new Intl.NumberFormat().format(amount);
    },
    createAmountObj (amountInUnits = 0, currency) {
      return {
        charge: amountInUnits,
        currency: currency || 'USD',
        display: this.getDisplayTotal(amountInUnits),
      };
    },
    getAmountInCurrency (amountObj, currency, withSymbol = true) {
      amountObj = amountObj || this.createAmountObj();
      let formattedAmount = this.getDisplayTotal(amountObj.charge);
      if (amountObj.currency === 'USD' && currency === 'NGN') {
        formattedAmount = this.getDisplayTotal(
          amountObj.charge * this.EXCHANGE_RATE
        );
      }
      if (amountObj.currency === 'NGN' && currency === 'USD') {
        formattedAmount = this.getDisplayTotal(
          amountObj.charge / this.EXCHANGE_RATE
        );
      }
      if (withSymbol && currency === 'USD') {
        return `$${formattedAmount}`;
      }
      if (withSymbol && currency === 'NGN') {
        return `₦${formattedAmount}`;
      }
      return formattedAmount;
    },
    calculateDeliveryFee ({
      deliveryChoice,
      countryCode = LOCALE.COUNTRY.NG.alpha2,
      addressIsWithinLagos,
      containsXlShipments = false,
    }) {
      const { deliveryFeeRates = {} } = this.API_SETTINGS;
      const defaultDeliveryFee = this.createAmountObj(0);

      if (deliveryChoice === this.DELIVERY_CHOICE_HOME) {
        // KEY FORMAT => country-addressIsWithinLagos-containsXlShipments
        const deliveryFeesRatesKeyMap = {
          'NG-true-true': 'lagosXL',
          'NG-true-false': 'lagosNonXL',
          'NG-false-true': 'nonLagosXL',
          'NG-false-false': 'nonLagosNonXL',
          GH: 'ghana',
        };

        const deliveryRateKey =
          countryCode === LOCALE.COUNTRY.GH.alpha2
            ? deliveryFeesRatesKeyMap[countryCode]
            : deliveryFeesRatesKeyMap[
                `${countryCode}-${addressIsWithinLagos}-${containsXlShipments}`
            ];
        return deliveryFeeRates[deliveryRateKey] || defaultDeliveryFee;
      }

      return defaultDeliveryFee;
    },
    getImageUrl (obj, options) {
      const { mediaKey = 'image', preferredFormatSize } = options || {};
      if (preferredFormatSize) {
        return (
          obj?.[mediaKey]?.formats?.[preferredFormatSize]?.url ||
          obj?.[mediaKey]?.url
        );
      }
      return obj?.[mediaKey]?.url;
    },
    getBackgroundImageUrl (obj, options) {
      const { mediaKey = 'backgroundImage', preferredFormatSize } =
        options || {};
      return this.getImageUrl(obj, { mediaKey, preferredFormatSize });
    },
    getIconUrl (obj, options) {
      const { mediaKey = 'icon', preferredFormatSize } = options || {};
      return this.getImageUrl(obj, { mediaKey, preferredFormatSize });
    },
    getAuthorImageUrl (obj, options) {
      const { mediaKey = 'authorImage', preferredFormatSize } = options || {};
      return this.getImageUrl(obj, { mediaKey, preferredFormatSize });
    },
    mapHeadScript (script) {
      const { innerHTML } = script || {};
      if (innerHTML) {
        return { ...script, innerHTML: JSON.stringify(innerHTML) };
      }
      return script;
    },
    isAndroid () {
      return navigator.userAgent.match(/Android/i);
    },
    isBlackBerry () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    isiOS () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    isOpera () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    isWindows () {
      return navigator.userAgent.match(/IEMobile/i);
    },
    isMobile () {
      return Boolean(
        this.isAndroid() ||
          this.isBlackBerry() ||
          this.isiOS() ||
          this.isOpera() ||
          this.isWindows()
      );
    },
    checkUserDeviceAndDirectToAppropriateAppInstance () {
      if (!this.isMobile()) {
        window.location = `${this.WebUrl}${this.$route.fullPath}`;
      }
    },
    debounce (fn, wait = 1000) {
      let timer;
      return function (...args) {
        if (timer) {
          clearTimeout(timer); // clear any pre-existing timer
        }
        const context = this; // get the current context
        timer = setTimeout(() => {
          fn.apply(context, args); // call the function if time expires
        }, wait);
      };
    },
    ...mapActions({
      fetchBusinessLocations: 'contact/fetchBusinessLocations',
      fetchContactNumbers: 'contact/fetchContactNumbers',
    }),
  },
});
