export default function ({ $axios, store, route, error }) {
  $axios.onRequest((config) => {
    Object.entries(route.query).forEach(([paramKey, paramValue]) => {
      if (paramKey.includes('FF_')) {
        config.headers[`X-${paramKey}`] = paramValue;
      }
    });

    return config;
  });

  $axios.onError((err) => {
    const { status } = err.request || {};

    // if (status === 404) {
    //   error({ statusCode: 404 });
    // }

    // Handling 500 Server Error
    if (store._vm.isServerError(status)) {
      error({ statusCode: status });
    }
  });
}
