
import StelaLink from '~/components/common/StelaLink';

export default {
  components: {
    StelaLink,
  },
  props: {
    resourcesIsOpen: {
      type: Boolean,
      default: false,
    },
    navContent: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      defaultNavContent: [
        {
          title: 'Featured Blog',
          subtitle: 'How to shop online like a pro',
          url: 'https://blog.heroshe.com/how-to-shop-online-like-a-pro',
          imageUrl: '/img/resources-img.png',
          learnMoreText: 'Visit our blog',
          learnMoreUrl: 'https://blog.heroshe.com',
        },
        {
          title: 'Help center',
          subtitle: '',
          url: '',
          helpfulLinks: [
            {
              url: '/help-center/getting-started',
              text: 'Getting started',
            },
            {
              url: '/help-center/faq/pricing-and-payments',
              text: 'Pricing & Payments',
            },
            {
              url: '/help-center/faq/shipping-and-delivery',
              text: 'Shipping & Delivery',
            },
          ],
          learnMoreText: 'Go to help center',
          learnMoreUrl: '/help-center',
        },
        {
          title: 'Frequently asked questions',
          subtitle: '',
          url: '',
          helpfulLinks: [
            {
              url: '/help-center/faq',
              text: ' What are your shipping rates?',
            },
            {
              url: '/help-center/faq',
              text: 'How long does it take to get my package delivered to Nigeria?',
            },
            {
              url: '/help-center/faq',
              text: 'Do you ship from Nigeria to the US and from other countries apart from the US to Nigeria?',
            },
          ],
          learnMoreText: 'See more',
          learnMoreUrl: '/help-center/faq',
        },
      ],
    };
  },
  computed: {
    resourcesNavContent () {
      if (this.navContent && this.navContent.length) {
        return this.navContent;
      }
      return this.defaultNavContent;
    },
    featuredInfo () {
      return this.resourcesNavContent?.[0];
    },
    helpfulInfo () {
      return this.resourcesNavContent?.[1];
    },
    highlightedInfo () {
      return this.resourcesNavContent?.[2];
    },
  },
};
