import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5b35888e = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _2a303be1 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _3ef77b61 = () => interopDefault(import('../pages/customer-reviews/index.vue' /* webpackChunkName: "pages/customer-reviews/index" */))
const _5f97a3e2 = () => interopDefault(import('../pages/help-center/index.vue' /* webpackChunkName: "pages/help-center/index" */))
const _5f9fe007 = () => interopDefault(import('../pages/pricing/index.vue' /* webpackChunkName: "pages/pricing/index" */))
const _712a4376 = () => interopDefault(import('../pages/split-pay/index.vue' /* webpackChunkName: "pages/split-pay/index" */))
const _349c362e = () => interopDefault(import('../pages/testimonials/index.vue' /* webpackChunkName: "pages/testimonials/index" */))
const _666b5fcc = () => interopDefault(import('../pages/track-my-shipment/index.vue' /* webpackChunkName: "pages/track-my-shipment/index" */))
const _1d6ae19e = () => interopDefault(import('../pages/about/story.vue' /* webpackChunkName: "pages/about/story" */))
const _2d53744c = () => interopDefault(import('../pages/contact/report-fraud.vue' /* webpackChunkName: "pages/contact/report-fraud" */))
const _47132df0 = () => interopDefault(import('../pages/help-center/getting-started.vue' /* webpackChunkName: "pages/help-center/getting-started" */))
const _7209273d = () => interopDefault(import('../pages/help-center/ocean-shipping.vue' /* webpackChunkName: "pages/help-center/ocean-shipping" */))
const _20c5d619 = () => interopDefault(import('../pages/help-center/pricing-and-payment.vue' /* webpackChunkName: "pages/help-center/pricing-and-payment" */))
const _3f1bb1c8 = () => interopDefault(import('../pages/help-center/search.vue' /* webpackChunkName: "pages/help-center/search" */))
const _87593f0e = () => interopDefault(import('../pages/help-center/shipping-and-delivery.vue' /* webpackChunkName: "pages/help-center/shipping-and-delivery" */))
const _a073a19e = () => interopDefault(import('../pages/help-center/shipping-guideline.vue' /* webpackChunkName: "pages/help-center/shipping-guideline" */))
const _3b3bfde0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f62bd87c = () => interopDefault(import('../pages/help-center/articles/view/_slug.vue' /* webpackChunkName: "pages/help-center/articles/view/_slug" */))
const _1b56d558 = () => interopDefault(import('../pages/help-center/articles/_slug.vue' /* webpackChunkName: "pages/help-center/articles/_slug" */))
const _0e4672e1 = () => interopDefault(import('../pages/help-center/faq/_slug.vue' /* webpackChunkName: "pages/help-center/faq/_slug" */))
const _2b03dab3 = () => interopDefault(import('../pages/help-center/videos/_slug.vue' /* webpackChunkName: "pages/help-center/videos/_slug" */))
const _82c52206 = () => interopDefault(import('../pages/campaign/_slug.vue' /* webpackChunkName: "pages/campaign/_slug" */))
const _26671f72 = () => interopDefault(import('../pages/legal/_slug.vue' /* webpackChunkName: "pages/legal/_slug" */))
const _25d99693 = () => interopDefault(import('../pages/solutions/_slug.vue' /* webpackChunkName: "pages/solutions/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _5b35888e,
    name: "about"
  }, {
    path: "/contact",
    component: _2a303be1,
    name: "contact"
  }, {
    path: "/customer-reviews",
    component: _3ef77b61,
    name: "customer-reviews"
  }, {
    path: "/help-center",
    component: _5f97a3e2,
    name: "help-center"
  }, {
    path: "/pricing",
    component: _5f9fe007,
    name: "pricing"
  }, {
    path: "/split-pay",
    component: _712a4376,
    name: "split-pay"
  }, {
    path: "/testimonials",
    component: _349c362e,
    name: "testimonials"
  }, {
    path: "/track-my-shipment",
    component: _666b5fcc,
    name: "track-my-shipment"
  }, {
    path: "/about/story",
    component: _1d6ae19e,
    name: "about-story"
  }, {
    path: "/contact/report-fraud",
    component: _2d53744c,
    name: "contact-report-fraud"
  }, {
    path: "/help-center/getting-started",
    component: _47132df0,
    name: "help-center-getting-started"
  }, {
    path: "/help-center/ocean-shipping",
    component: _7209273d,
    name: "help-center-ocean-shipping"
  }, {
    path: "/help-center/pricing-and-payment",
    component: _20c5d619,
    name: "help-center-pricing-and-payment"
  }, {
    path: "/help-center/search",
    component: _3f1bb1c8,
    name: "help-center-search"
  }, {
    path: "/help-center/shipping-and-delivery",
    component: _87593f0e,
    name: "help-center-shipping-and-delivery"
  }, {
    path: "/help-center/shipping-guideline",
    component: _a073a19e,
    name: "help-center-shipping-guideline"
  }, {
    path: "/",
    component: _3b3bfde0,
    name: "index"
  }, {
    path: "/help-center/articles/view/:slug?",
    component: _f62bd87c,
    name: "help-center-articles-view-slug"
  }, {
    path: "/help-center/articles/:slug",
    component: _1b56d558,
    name: "help-center-articles-slug"
  }, {
    path: "/help-center/faq/:slug",
    component: _0e4672e1,
    name: "help-center-faq-slug"
  }, {
    path: "/help-center/videos/:slug",
    component: _2b03dab3,
    name: "help-center-videos-slug"
  }, {
    path: "/campaign/:slug?",
    component: _82c52206,
    name: "campaign-slug"
  }, {
    path: "/legal/:slug?",
    component: _26671f72,
    name: "legal-slug"
  }, {
    path: "/solutions/:slug?",
    component: _25d99693,
    name: "solutions-slug"
  }, {
    path: "/help-center/faq",
    component: _0e4672e1,
    name: "help-center-faq"
  }, {
    path: "/help-center/videos",
    component: _2b03dab3,
    name: "help-center-videos"
  }, {
    path: "/help-center/articles",
    component: _1b56d558,
    name: "help-center-articles"
  }, {
    path: "/ng/about",
    component: _5b35888e,
    name: "ng-about"
  }, {
    path: "/ng/contact",
    component: _2a303be1,
    name: "ng-contact"
  }, {
    path: "/ng/customer-reviews",
    component: _3ef77b61,
    name: "ng-customer-reviews"
  }, {
    path: "/ng/help-center",
    component: _5f97a3e2,
    name: "ng-help-center"
  }, {
    path: "/ng/pricing",
    component: _5f9fe007,
    name: "ng-pricing"
  }, {
    path: "/ng/split-pay",
    component: _712a4376,
    name: "ng-split-pay"
  }, {
    path: "/ng/testimonials",
    component: _349c362e,
    name: "ng-testimonials"
  }, {
    path: "/ng/track-my-shipment",
    component: _666b5fcc,
    name: "ng-track-my-shipment"
  }, {
    path: "/ng/about/story",
    component: _1d6ae19e,
    name: "ng-about-story"
  }, {
    path: "/ng/contact/report-fraud",
    component: _2d53744c,
    name: "ng-contact-report-fraud"
  }, {
    path: "/ng/help-center/getting-started",
    component: _47132df0,
    name: "ng-help-center-getting-started"
  }, {
    path: "/ng/help-center/ocean-shipping",
    component: _7209273d,
    name: "ng-help-center-ocean-shipping"
  }, {
    path: "/ng/help-center/pricing-and-payment",
    component: _20c5d619,
    name: "ng-help-center-pricing-and-payment"
  }, {
    path: "/ng/help-center/search",
    component: _3f1bb1c8,
    name: "ng-help-center-search"
  }, {
    path: "/ng/help-center/shipping-and-delivery",
    component: _87593f0e,
    name: "ng-help-center-shipping-and-delivery"
  }, {
    path: "/ng/help-center/shipping-guideline",
    component: _a073a19e,
    name: "ng-help-center-shipping-guideline"
  }, {
    path: "/ng/",
    component: _3b3bfde0,
    name: "ng-index"
  }, {
    path: "/ng/help-center/articles/view/:slug?",
    component: _f62bd87c,
    name: "ng-help-center-articles-view-slug"
  }, {
    path: "/ng/help-center/articles/:slug",
    component: _1b56d558,
    name: "ng-help-center-articles-slug"
  }, {
    path: "/ng/help-center/faq/:slug",
    component: _0e4672e1,
    name: "ng-help-center-faq-slug"
  }, {
    path: "/ng/help-center/videos/:slug",
    component: _2b03dab3,
    name: "ng-help-center-videos-slug"
  }, {
    path: "/ng/campaign/:slug?",
    component: _82c52206,
    name: "ng-campaign-slug"
  }, {
    path: "/ng/legal/:slug?",
    component: _26671f72,
    name: "ng-legal-slug"
  }, {
    path: "/ng/solutions/:slug?",
    component: _25d99693,
    name: "ng-solutions-slug"
  }, {
    path: "/ng/help-center/faq",
    component: _0e4672e1,
    name: "ng-help-center-faq"
  }, {
    path: "/ng/help-center/videos",
    component: _2b03dab3,
    name: "ng-help-center-videos"
  }, {
    path: "/ng/help-center/articles",
    component: _1b56d558,
    name: "ng-help-center-articles"
  }, {
    path: "/gh/about",
    component: _5b35888e,
    name: "gh-about"
  }, {
    path: "/gh/contact",
    component: _2a303be1,
    name: "gh-contact"
  }, {
    path: "/gh/customer-reviews",
    component: _3ef77b61,
    name: "gh-customer-reviews"
  }, {
    path: "/gh/help-center",
    component: _5f97a3e2,
    name: "gh-help-center"
  }, {
    path: "/gh/pricing",
    component: _5f9fe007,
    name: "gh-pricing"
  }, {
    path: "/gh/split-pay",
    component: _712a4376,
    name: "gh-split-pay"
  }, {
    path: "/gh/testimonials",
    component: _349c362e,
    name: "gh-testimonials"
  }, {
    path: "/gh/track-my-shipment",
    component: _666b5fcc,
    name: "gh-track-my-shipment"
  }, {
    path: "/gh/about/story",
    component: _1d6ae19e,
    name: "gh-about-story"
  }, {
    path: "/gh/contact/report-fraud",
    component: _2d53744c,
    name: "gh-contact-report-fraud"
  }, {
    path: "/gh/help-center/getting-started",
    component: _47132df0,
    name: "gh-help-center-getting-started"
  }, {
    path: "/gh/help-center/ocean-shipping",
    component: _7209273d,
    name: "gh-help-center-ocean-shipping"
  }, {
    path: "/gh/help-center/pricing-and-payment",
    component: _20c5d619,
    name: "gh-help-center-pricing-and-payment"
  }, {
    path: "/gh/help-center/search",
    component: _3f1bb1c8,
    name: "gh-help-center-search"
  }, {
    path: "/gh/help-center/shipping-and-delivery",
    component: _87593f0e,
    name: "gh-help-center-shipping-and-delivery"
  }, {
    path: "/gh/help-center/shipping-guideline",
    component: _a073a19e,
    name: "gh-help-center-shipping-guideline"
  }, {
    path: "/gh/",
    component: _3b3bfde0,
    name: "gh-index"
  }, {
    path: "/gh/help-center/articles/view/:slug?",
    component: _f62bd87c,
    name: "gh-help-center-articles-view-slug"
  }, {
    path: "/gh/help-center/articles/:slug",
    component: _1b56d558,
    name: "gh-help-center-articles-slug"
  }, {
    path: "/gh/help-center/faq/:slug",
    component: _0e4672e1,
    name: "gh-help-center-faq-slug"
  }, {
    path: "/gh/help-center/videos/:slug",
    component: _2b03dab3,
    name: "gh-help-center-videos-slug"
  }, {
    path: "/gh/campaign/:slug?",
    component: _82c52206,
    name: "gh-campaign-slug"
  }, {
    path: "/gh/legal/:slug?",
    component: _26671f72,
    name: "gh-legal-slug"
  }, {
    path: "/gh/solutions/:slug?",
    component: _25d99693,
    name: "gh-solutions-slug"
  }, {
    path: "/gh/help-center/faq",
    component: _0e4672e1,
    name: "gh-help-center-faq"
  }, {
    path: "/gh/help-center/videos",
    component: _2b03dab3,
    name: "gh-help-center-videos"
  }, {
    path: "/gh/help-center/articles",
    component: _1b56d558,
    name: "gh-help-center-articles"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
