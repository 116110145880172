
import StelaLink from '~/components/common/StelaLink';

export default {
  components: {
    StelaLink,
  },
  props: {
    solutionsIsOpen: {
      type: Boolean,
      default: false,
    },
    navContent: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      defaultNavContent: [
        {
          title: 'Small Business',
          subtitle: 'Shipping from various stores? Manage, pay and choose delivery all in one place',
          url: '/solutions/small-business',
          learnMoreText: 'Learn more'
        },
        {
          title: 'Personal Shipping',
          subtitle: 'Receive packages in your name and we’ll ship it to your doorstep',
          url: '/solutions/personal-shipping',
          learnMoreText: 'Learn more',
        },
        {
          title: 'What our customers say',
          subtitle: 'We help thousands of people move shipments everyday. Here’s what more than 100 of them have to say about Heroshe.',
          url: this.CUSTOMER_REVIEW_URL,
          learnMoreText: 'Read customer reviews',
        }
      ],
    };
  },
  computed: {
    solutionNavContent () {
      if (this.navContent && this.navContent.length) {
        return this.navContent;
      }
      return this.defaultNavContent;
    },
    topTwoSolutions () {
      const filterTruthyValues = x => x;
      return [
        this.solutionNavContent?.[1],
        this.solutionNavContent?.[0],
      ].filter(filterTruthyValues);
    },
    highlightedInfo () {
      return this.solutionNavContent?.[2];
    },
  },
};
