
export default {
  name: 'Dropdown',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    minWidth: {
      type: String,
      default: 'fit-content',
    },
    maxWidth: {
      type: String,
      default: 'max-content',
    },
    direction: {
      type: String,
      default: 'bottom',
    },
    arrow: {
      type: String,
      default: 'center',
    },
    showArrow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getWrapperStyle () {
      return `min-width: ${this.minWidth}; max-width: ${this.maxWidth};`;
    },
  },
};
