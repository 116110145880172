
import { mapGetters, mapActions } from 'vuex';
import SlideTransition from '~/components/transitions/SlideTransition';
import ResourcesNavContent from '~/components/resources/ResourcesNavContent';
import SolutionsNavContent from '~/components/solutions/SolutionsNavContent';
import getHeaderData from '~/assets/data/get-header-data';

export default {
  name: 'StelaHeaderNavigations',
  components: {
    SlideTransition,
    ResourcesNavContent,
    SolutionsNavContent,
  },
  data () {
    return {
      sideNavigationsMenuIsOpen: '',
    };
  },
  computed: {
    commonKey () {
      return 'header-data';
    },
    headerData () {
      return this.allCommonData[this.commonKey] || {};
    },
    defaultHeaderData () {
      const dataObj = {
        CUSTOMER_REVIEW_URL: this.CUSTOMER_REVIEW_URL,
        BLOG_URL: this.BLOG_URL,
      };

      return getHeaderData(dataObj);
    },
    mainHeaderLinks () {
      return this.headerData?.mainHeaderLinks || this.defaultHeaderData.mainHeaderLinks;
    },
    ...mapGetters({
      allCommonData: 'common/commonData',
    }),
  },
  methods: {
    hasContent (content) {
      return content.length > 0;
    },
    toggleSideNavigationsMenu (title) {
      if (this.sideNavigationsMenuIsOpen === title) {
        this.sideNavigationsMenuIsOpen = '';
        return;
      }
      this.sideNavigationsMenuIsOpen = title;
    },
    getActiveClass (navLink) {
      return this.$route.path.toLowerCase().includes(navLink.toLowerCase())
        ? 'active'
        : '';
    },
    ...mapActions({}),
  },
};
