
import { mapActions } from 'vuex';
import LOCALE from '~/assets/constants/LOCALE_DESTINATION_COUNTRY';
import Modal from '~/components/common/Modal';

export default {
  components: {
    Modal,
  },
  props: {
    asDropdown: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      showSelectCountryModal: false,
      selectedCountry: '',
      showDropdown: true,
    };
  },
  computed: {
    countries () {
      return this.excludeFlaggedCountries(LOCALE.COUNTRIES, this.featureFlaggedDestinationCountries);
    },
  },
  watch: {
    async selectedCountry (newVal) {
      if (newVal) {
        this.setPageCountry(newVal);
        this.closeModal();
        await window.scrollTo(0, 0);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    },
  },
  mounted () {
    this.$eventBus.$on('open-select-country-modal', () => {
      this.showSelectCountryModal = true;
    });
  },
  beforeDestroy () {
    this.$eventBus.$off('open-select-country-modal');
  },
  methods: {
    closeModal () {
      this.showSelectCountryModal = false;
    },
    ...mapActions({
      setPageCountry: 'page/setPageCountry',
    }),
  },
};
