
import StelaErrorPageLayout from '~/components/layout/StelaErrorPageLayout';
export default {
  components: { StelaErrorPageLayout },
  layout: 'error',
  props: {
    error: {
      type: Object,
      default: null
    },
    pageTitle: {
      type: String,
      default: null,
    },
    pageTitleUrl: {
      type: String,
      default: null,
    },
    isPrivatePage: {
      type: Boolean,
      default: false,
    },
    showMainMenu: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      errorText: {
        404: 'The page you are trying to access does not exist or has been moved.\nTry going back to our homepage.',
        500: 'Ooops, Something went wrong!\nSorry for the inconvenience, We are working on it.'
      },
    };
  },
};
