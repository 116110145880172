import { AVAILABLE_CONFIG_KEY } from './AVAILABLE_CONFIG_KEY';

export const API_SETTINGS_CONFIG_KEY = {
  deliveryFeeRates: {
    lagosNonXL: AVAILABLE_CONFIG_KEY.ordering.delivery_fee_lagos_regular,
    lagosXL: AVAILABLE_CONFIG_KEY.ordering.delivery_fee_lagos_xl,
    nonLagosNonXL: AVAILABLE_CONFIG_KEY.ordering.delivery_fee_not_lagos_regular,
    nonLagosXL: AVAILABLE_CONFIG_KEY.ordering.delivery_fee_not_lagos_xl,
    ghana: AVAILABLE_CONFIG_KEY.ordering.delivery_fee_ghana,
  },
  exchangeRate: AVAILABLE_CONFIG_KEY.ordering.naira_to_dollar_exchange_rate,
  pricePerPound: AVAILABLE_CONFIG_KEY.shipping.price_per_pound,
};

export default API_SETTINGS_CONFIG_KEY;
