
import { mapGetters, mapActions } from 'vuex';
import OfflineNotice from '~/components/common/OfflineNotice';
import StelaPageHeader from '~/components/common/StelaPageHeader';
import StelaSideNavigations from '~/components/common/StelaSideNavigations';

export default {
  components: {
    OfflineNotice,
    StelaPageHeader,
    StelaSideNavigations
  },
  props: {
    pageTitle: {
      type: String,
      default: null,
    },
    pageTitleUrl: {
      type: String,
      default: null,
    },
    isPrivatePage: {
      type: Boolean,
      default: false,
    },
    showMainMenu: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    isLoggedIn () {
      return false;
    },
    ...mapGetters({
      showPageSidebar: 'page/showPageSidebar',
    }),
  },
  created () {
    if (this.isPrivatePage && !this.isLoggedIn) {
      // return to homepage.
      return this.$router.push('/');
    }
  },
  mounted () {},
  beforeDestroy () {},
  methods: {
    ...mapActions({
      togglePageSidebar: 'page/togglePageSidebar',
    }),
  },
};
