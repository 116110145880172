const getServiceBasePath = version => `/shipping/${version}`;

export default ($axios, $apiVersion = 'v1') => ({
  shipments: {
    track: ({ idOrTrackingNumber, headers }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/public/shipments/${idOrTrackingNumber}`;
      return $axios.get(urlPath, { headers });
    },
  },
  orders: {
    estimate: ({ headers, data }) => {
      const urlPath = `${getServiceBasePath($apiVersion)}/public/orders/estimate`;
      return $axios.post(urlPath, data, { headers });
    },
  }
});
