export const state = () => ({
  businessLocationsDataIsLoading: false,
  usWarehouseAddressData: [],
  ukWarehouseAddressData: [],
  ngWarehouseAddressData: [],
  ghWarehouseAddressData: [],
  cnWarehouseAddressData: [],
  usWarehouseAddressDataError: '',
  ukWarehouseAddressDataError: '',
  ngWarehouseAddressDataError: '',
  ghWarehouseAddressDataError: '',
  cnWarehouseAddressDataError: '',
  contactNumbersDataIsLoading: false,
  usContactNumbersData: [],
  ukContactNumbersData: [],
  ngContactNumbersData: [],
  ghContactNumbersData: [],
  cnContactNumbersData: [],
  contactNumbersDataError: '',
});

export const mutations = {
  setBusinessLocationsDataIsLoading (state, isLoading) {
    state.businessLocationsDataIsLoading = isLoading;
  },
  setUsWarehouseAddressData (state, obj) {
    state.usWarehouseAddressData = obj;
  },
  setUkWarehouseAddressData (state, obj) {
    state.ukWarehouseAddressData = obj;
  },
  setNgWarehouseAddressData (state, obj) {
    state.ngWarehouseAddressData = obj;
  },
  setGhWarehouseAddressData (state, obj) {
    state.ghWarehouseAddressData = obj;
  },
  setCnWarehouseAddressData (state, obj) {
    state.cnWarehouseAddressData = obj;
  },
  setUsWarehouseAddressDataError (state, errMsg) {
    state.usWarehouseAddressDataError = errMsg;
  },
  setUkWarehouseAddressDataError (state, errMsg) {
    state.ukWarehouseAddressDataError = errMsg;
  },
  setNgWarehouseAddressDataError (state, errMsg) {
    state.ngWarehouseAddressDataError = errMsg;
  },
  setGhWarehouseAddressDataError (state, errMsg) {
    state.ghWarehouseAddressDataError = errMsg;
  },
  setCnWarehouseAddressDataError (state, errMsg) {
    state.cnWarehouseAddressDataError = errMsg;
  },
  setContactNumbersDataIsLoading (state, isLoading) {
    state.contactNumbersDataIsLoading = isLoading;
  },
  setUsContactNumbersData (state, obj) {
    state.usContactNumbersData = obj;
  },
  setUkContactNumbersData (state, obj) {
    state.ukContactNumbersData = obj;
  },
  setNgContactNumbersData (state, obj) {
    state.ngContactNumbersData = obj;
  },
  setGhContactNumbersData (state, obj) {
    state.ghContactNumbersData = obj;
  },
  setCnContactNumbersData (state, obj) {
    state.cnContactNumbersData = obj;
  },
  setContactNumbersError (state, errMsg) {
    state.contactNumbersDataError = errMsg;
  },
};

export const getters = {
  businessLocationsDataIsLoading: (state) => {
    return state.businessLocationsDataIsLoading;
  },
  usWarehouseAddressData: (state) => {
    return state.usWarehouseAddressData;
  },
  ukWarehouseAddressData: (state) => {
    return state.ukWarehouseAddressData;
  },
  ngWarehouseAddressData: (state) => {
    return state.ngWarehouseAddressData;
  },
  ghWarehouseAddressData: (state) => {
    return state.ghWarehouseAddressData;
  },
  cnWarehouseAddressData: (state) => {
    return state.cnWarehouseAddressData;
  },
  usWarehouseAddressDataError: (state) => {
    return state.usWarehouseAddressDataError;
  },
  ukWarehouseAddressDataError: (state) => {
    return state.ukWarehouseAddressDataError;
  },
  cnWarehouseAddressDataError: (state) => {
    return state.cnWarehouseAddressDataError;
  },
  contactNumbersDataIsLoading: (state) => {
    return state.contactNumbersDataIsLoading;
  },
  usContactNumbersData: (state) => {
    return state.usContactNumbersData;
  },
  ukContactNumbersData: (state) => {
    return state.ukContactNumbersData;
  },
  ngContactNumbersData: (state) => {
    return state.ngContactNumbersData;
  },
  ghContactNumbersData: (state) => {
    return state.ghContactNumbersData;
  },
  cnContactNumbersData: (state) => {
    return state.cnContactNumbersData;
  },
  contactNumbersDataError: (state) => {
    return state.contactNumbersDataError;
  },
};

export const actions = {
  async fetchBusinessLocations ({ commit }) {
    this.commit('contact/setBusinessLocationsDataIsLoading', true);

    try {
      const data = await this.dispatch('contact/fetchBusinessLocationsData');
      const usAddresses = data.filter(x => x.country_code === 'US');
      const ukAddresses = data.filter(x => x.country_code === 'GB');
      const ngAddresses = data.filter(x => x.country_code === 'NG');
      const ghAddresses = data.filter(x => x.country_code === 'GH');
      const cnAddresses = data.filter(x => x.country_code === 'CN');
      this.commit('contact/setUsWarehouseAddressData', usAddresses || []);
      this.commit('contact/setUkWarehouseAddressData', ukAddresses || []);
      this.commit('contact/setNgWarehouseAddressData', ngAddresses || []);
      this.commit('contact/setGhWarehouseAddressData', ghAddresses || []);
      this.commit('contact/setCnWarehouseAddressData', cnAddresses || []);
    } catch (error) {
      this.commit('contact/setUsWarehouseAddressDataError', 'Error loading US Warehouse Address');
      this.commit('contact/setUkWarehouseAddressDataError', 'Error loading UK Warehouse Address');
      this.commit('contact/setNgWarehouseAddressDataError', 'Error loading NG Warehouse Address');
      this.commit('contact/setCnWarehouseAddressDataError', 'Error loading CN Warehouse Address');
    } finally {
      this.commit('contact/setBusinessLocationsDataIsLoading', false);
    }
  },
  fetchBusinessLocationsData () {
    return new Promise((resolve, reject) => {
      return this.$tera.collections['business-locations']()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchContactNumbers ({ commit }) {
    this.commit('contact/setContactNumbersDataIsLoading', true);

    try {
      const data = await this.dispatch('contact/fetchContactNumbersData');
      const usContacts = data.filter(x => x.country_code === 'US');
      const ukContacts = data.filter(x => x.country_code === 'GB');
      const ngContacts = data.filter(x => x.country_code === 'NG');
      const ghContacts = data.filter(x => x.country_code === 'GH');
      const cnContacts = data.filter(x => x.country_code === 'CN');
      this.commit('contact/setUsContactNumbersData', usContacts || []);
      this.commit('contact/setUkContactNumbersData', ukContacts || []);
      this.commit('contact/setNgContactNumbersData', ngContacts || []);
      this.commit('contact/setGhContactNumbersData', ghContacts || []);
      this.commit('contact/setCnContactNumbersData', cnContacts || []);
    } catch (error) {
      this.commit('contact/setContactNumbersError', 'Error loading contact numbers');
    } finally {
      this.commit('contact/setContactNumbersDataIsLoading', false);
    }
  },
  fetchContactNumbersData () {
    return new Promise((resolve, reject) => {
      return this.$tera.collections['contact-numbers']()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
