const LOCALE_DESTINATION_COUNTRY = {
  NG: {
    alpha2: 'NG',
    code: 'NGA',
    name: 'Nigeria',
    emoji: '🇳🇬',
    flagIconUrl: '/svg/flags/icon-flag-ng.svg',
  },
  GH: {
    locale: 'en-GH',
    alpha2: 'GH',
    code: 'GHA',
    name: 'Ghana',
    emoji: '🇬🇭',
    flagIconUrl: '/svg/flags/icon-flag-gh.svg',
  },
};

export const LOCALE_DESTINATION_COUNTRIES = Object.values(LOCALE_DESTINATION_COUNTRY);

const LOCALE_DESTINATION = {
  COUNTRY: LOCALE_DESTINATION_COUNTRY,
  COUNTRIES: LOCALE_DESTINATION_COUNTRIES,
};

export const HOME_COUNTRY = LOCALE_DESTINATION_COUNTRY.NG;

export default LOCALE_DESTINATION;
