
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'medium',
      validator (val) {
        return ['small', 'medium', 'large', 'full', 'video'].includes(val);
      }
    },
    cannotclickout: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      sizeMap: {
        small: 'sm',
        medium: 'md',
        large: 'lg',
        full: 'full',
        video: 'video',
      }
    };
  },
  methods: {
    closeModal () {
      if (this.cannotclickout) { return false; }
      this.$emit('close');
    }
  }
};
