import LOCALE, { HOME_COUNTRY } from '~/assets/constants/LOCALE_DESTINATION_COUNTRY';

export const state = () => ({
  showPageSidebar: false,
  pageDataIsLoading: false,
  pageDataError: '',
  pageData: {},
  pageKeyData: {
    'home-page': {
      url: '/',
      allowLocale: true,
    },
    'solutions-page': {
      url: '/solutions',
      allowLocale: false,
    },
    'pricing-page': {
      url: '/pricing',
      allowLocale: true,
    },
    'legal-page': {
      url: '/legal',
      allowLocale: false,
    },
    'about-page': {
      url: '/about',
      allowLocale: false,
    },
    'founder-story-page': {
      url: '/about/story',
      allowLocale: false,
    },
    'contact-page': {
      url: '/contact',
      allowLocale: false,
    },
    'report-fraud-page': {
      url: '/contact/report-fraud',
      allowLocale: false,
    },
    'help-center-page': {
      url: '/help-center',
      allowLocale: false,
    },
    'getting-started-page': {
      url: '/help-center/getting-started',
      allowLocale: false,
    },
    'faq-page': {
      url: '/help-center/faq',
      allowLocale: true,
    },
    'ocean-shipping-page': {
      url: '/help-center/ocean-shipping',
      allowLocale: false,
    },
    'shipping-guideline-page': {
      url: '/help-center/shipping-guideline',
      allowLocale: false,
    },
    'video-resources-page': {
      url: '/help-center',
      allowLocale: false,
    },
  },
  currentSolutionsPageData: {},
  pageCountry: HOME_COUNTRY.alpha2,
  pageLocale: undefined,
});

export const mutations = {
  setShowPageSidebar (state, showPageSidebar) {
    state.showPageSidebar = showPageSidebar;
  },
  setPageDataIsLoading (state, isLoading) {
    state.pageDataIsLoading = isLoading;
  },
  setPageDataError (state, errorMessage) {
    state.pageDataError = errorMessage;
  },
  setPageData (state, { pageKey, data }) {
    state.pageData = {
      ...state.pageData,
      [pageKey]: data,
    };
  },
  setCurrentSolutionsPageData (state, data) {
    state.currentSolutionsPageData = data;
  },
  setPageCountry (state, countryCode) {
    if (!countryCode) {
      return;
    }
    if (LOCALE.COUNTRY[countryCode]) {
      state.pageCountry = countryCode;
      state.pageLocale = LOCALE.COUNTRY[countryCode]?.locale;
      this.$cookies.set('country', state.pageCountry);
    }
  },
};

export const getters = {
  showPageSidebar: (state) => {
    return state.showPageSidebar;
  },
  pageDataIsLoading: (state) => {
    return state.pageDataIsLoading;
  },
  pageDataError: (state) => {
    return state.pageDataError;
  },
  pageData: (state) => {
    return state.pageData;
  },
  pageKeyData: (state) => {
    return state.pageKeyData;
  },
  currentSolutionsPageData (state) {
    return state.currentSolutionsPageData;
  },
  pageCountry (state) {
    return state.pageCountry;
  },
  pageLocale (state) {
    return state.pageLocale;
  },
};

export const actions = {
  setPageCountry ({ commit }, countryCode) {
    commit('setPageCountry', countryCode);
  },
  openPageSidebar ({ commit }) {
    commit('setShowPageSidebar', true);
  },
  closePageSidebar ({ commit }) {
    commit('setShowPageSidebar', false);
  },
  togglePageSidebar ({ commit, rootState }) {
    const { page } = rootState;
    const { showPageSidebar } = page;
    commit('setShowPageSidebar', !showPageSidebar);
  },
  fetchPageData ({ commit }, pageKey) {
    const allowLocale = this.getters['page/pageKeyData'][pageKey].allowLocale;
    const locale = allowLocale ? this.getters['page/pageLocale'] : undefined;
    const options = {
      query: {
        _locale: locale,
      },
    };

    this.commit('page/setPageDataIsLoading', true);
    return new Promise((resolve, reject) => {
      return this.$tera.pages[pageKey](options)
        .then((data) => {
          this.commit('page/setPageData', { pageKey, data });
          resolve(data);
        })
        .catch((error) => {
          this.commit('page/setPageDataError', 'Error loading page data');
          reject(error);
        })
        .finally(() => {
          this.commit('page/setPageDataError', '');
          this.commit('page/setPageDataIsLoading', false);
        });
    });
  },
  fetchCurrentSolutionsPageData ({ commit }, slug) {
    return new Promise((resolve, reject) => {
      return this.$tera.collections['solution-pages'](slug)
        .then((data) => {
          // delete keys with value = null
          Object.keys(data).forEach((key) => {
            if (data[key] === null || data[key] === '') {
              delete data[key];
            }
          });
          this.commit('page/setCurrentSolutionsPageData', data || {});
          resolve(data);
        })
        .catch((error) => {
          this.commit('page/setPageDataError', 'Error loading page');
          reject(error);
        })
        .finally(() => {
          this.commit('page/setPageDataError', '');
          this.commit('page/setPageDataIsLoading', false);
        });
    });
  },
};
