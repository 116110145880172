export const state = () => ({
  commonDataIsLoading: false,
  commonDataError: '',
  commonData: {},
  commonKeyToUrlMap: {
    'header-data': '/',
    'notification-banner': '/',
    'footer-data': '/',
  },
});

export const mutations = {
  setCommonDataIsLoading (state, isLoading) {
    state.CommonDataIsLoading = isLoading;
  },
  setCommonDataError (state, errorMessage) {
    state.CommonDataError = errorMessage;
  },
  setCommonData (state, { commonKey, data }) {
    state.commonData = {
      ...state.commonData,
      [commonKey]: data,
    };
  },
};

export const getters = {
  commonDataIsLoading: (state) => {
    return state.commonDataIsLoading;
  },
  commonDataError: (state) => {
    return state.commonDataError;
  },
  commonData: (state) => {
    return state.commonData;
  },
  commonKeyToUrlMap: (state) => {
    return state.commonKeyToUrlMap;
  },
};

export const actions = {
  fetchCommonData ({ commit }, commonKey) {
    this.commit('common/setCommonDataIsLoading', true);
    return new Promise((resolve, reject) => {
      return this.$tera.commons[commonKey]()
        .then((data) => {
          this.commit('common/setCommonData', { commonKey, data });
          resolve(data);
        })
        .catch((error) => {
          this.commit('common/setCommonDataError', 'Error loading common data');
          reject(error);
        })
        .finally(() => {
          this.commit('common/setCommonDataError', '');
          this.commit('common/setCommonDataIsLoading', false);
        });
    });
  },
};
