
import { mapGetters, mapActions } from 'vuex';
import LOCALE, { HOME_COUNTRY } from '~/assets/constants/LOCALE_DESTINATION_COUNTRY';
import StelaHeaderNavigations from '~/components/common/StelaHeaderNavigations';
import Dropdown from '~/components/common/Dropdown';
import SelectCountryModal from '~/components/modals/SelectCountryModal';
import { logoMixin } from '~/mixins';

export default {
  name: 'StelaPageHeader',
  components: {
    StelaHeaderNavigations,
    Dropdown,
    SelectCountryModal
  },
  mixins: [logoMixin],
  props: {
    pageTitle: {
      type: String,
      default: null
    },
    pageTitleUrl: {
      type: String,
      default: null
    },
    menuIcon: {
      type: String,
      default: '/svg/icon-menu.svg'
    },
    isLoggedIn: {
      type: Boolean,
      default: false
    },
    showMainMenu: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    country () {
      return (
        LOCALE.COUNTRIES.find(country => country.alpha2 === this.pageCountry) ||
        HOME_COUNTRY
      );
    },
    countryDropdownOptions () {
      return [
        {
          title: 'Nigeria',
          click: () => {}
        },
        {
          title: 'Ghana',
          click: () => {}
        }
      ];
    },
    ...mapGetters({})
  },
  methods: {
    ...mapActions({
      openPageSidebar: 'page/openPageSidebar'
    })
  }
};
