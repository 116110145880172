import { API_SERVICE } from './API_SERVICE';

export const AVAILABLE_CONFIG_KEY = {
  [API_SERVICE.ordering]: {
    delivery_fee_ghana: 'delivery_fee_ghana',
    delivery_fee_lagos_regular: 'delivery_fee_lagos_regular',
    delivery_fee_lagos_xl: 'delivery_fee_lagos_xl',
    delivery_fee_not_lagos_regular: 'delivery_fee_not_lagos_regular',
    delivery_fee_not_lagos_xl: 'delivery_fee_not_lagos_xl',
    naira_to_dollar_exchange_rate: 'naira_to_dollar_exchange_rate',
  },
  [API_SERVICE.shipping]: {
    price_per_pound: 'price_per_pound',
  },
};

export default AVAILABLE_CONFIG_KEY;
