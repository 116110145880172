export default (dataObj) => {
  const { CUSTOMER_REVIEW_URL = '', BLOG_URL = 'https://blog.heroshe.com' } = dataObj || {};

  return {
    mainHeaderLinks: [
      {
        title: 'Solutions',
        url: '/solutions',
        content: [
          {
            iconUrl: '/svg/personal-shopping.svg',
            title: 'Personal Shipping',
            subtitle: 'Receive packages in your name and we’ll ship it to your doorstep',
            learnMoreText: 'Learn more',
            learnMoreUrl: '/solutions/personal-shipping',
          },
          {
            iconUrl: '/svg/small-business.svg',
            title: 'Small Business',
            subtitle: 'Shipping from various stores? Manage, pay and choose delivery all in one place',
            learnMoreText: 'Learn more',
            learnMoreUrl: '/solutions/small-business',
          },
          {
            title: 'What our customers say',
            subtitle: 'We help thousands of people move shipments everyday. Here’s what more than 100 of them have to say about Heroshe.',
            learnMoreText: 'Read customer reviews',
            learnMoreUrl: CUSTOMER_REVIEW_URL,
          }
        ],
      },
      {
        title: 'Pricing',
        url: '/pricing',
        content: [],
      },
      {
        title: 'About us',
        url: '/about',
        content: [],
      },
      {
        title: 'Contact',
        url: '/contact',
        content: [],
      },
      {
        title: 'Resources',
        url: '/help-center',
        content: [

          {
            title: 'Featured Blog',
            subtitle: 'How to shop online like a pro',
            url: 'https://blog.heroshe.com/how-to-shop-online-like-a-pro',
            imageUrl: '/img/resources-img.png',
            learnMoreText: 'Visit our blog',
            learnMoreUrl: BLOG_URL,
          },
          {
            title: 'Help center',
            subtitle: '',
            url: '',
            helpfulLinks: [
              {
                url: '/help-center/getting-started',
                text: 'Getting started',
              },
              {
                url: '/help-center/faq/pricing-and-payments',
                text: 'Pricing & Payments',
              },
              {
                url: '/help-center/faq/shipping-and-delivery',
                text: 'Shipping & Delivery',
              },
            ],
            learnMoreText: 'Go to help center',
            learnMoreUrl: '/help-center',
          },
          {
            title: 'Frequently asked questions',
            subtitle: '',
            url: '',
            helpfulLinks: [
              {
                url: '/help-center/faq',
                text: ' What are your shipping rates?',
              },
              {
                url: '/help-center/faq',
                text: 'How long does it take to get my package delivered to Nigeria?',
              },
              {
                url: '/help-center/faq',
                text: 'Do you ship from Nigeria to the US and from other countries apart from the US to Nigeria?',
              },
            ],
            learnMoreText: 'See more',
            learnMoreUrl: '/help-center/faq',
          },
        ],
      },
    ]
  };
};
