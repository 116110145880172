export const logoMixin = {
  data () {
    return {
      defaultLogo: '/svg/logo/heroshe.svg',
      christmasLogo: '/svg/logo/heroshe-christmas-logo-anime.gif',
      valentineLogo: '/svg/logo/new-heroshe-valentine-anime.gif',
    };
  },
  computed: {
    logo () {
      if (
        this.isChristmasTime()
      ) {
        return this.christmasLogo;
      }
      if (
        this.isValentinesTime()
      ) {
        return this.valentineLogo;
      }
      return this.defaultLogo;
    },
  },
  methods: {
    isChristmasTime () {
      const currentDate = new Date();
      const christmasStart = new Date(currentDate.getFullYear(), 11, 1);
      const christmasEnd = new Date(currentDate.getFullYear(), 0, 3);

      return currentDate >= christmasStart && currentDate <= christmasEnd;
    },

    isValentinesTime () {
      const currentDate = new Date();
      const valentineStart = new Date(currentDate.getFullYear(), 1, 1);
      const valentineEnd = new Date(currentDate.getFullYear(), 1, 23);

      return currentDate >= valentineStart && currentDate <= valentineEnd;
    },
  },
};

export default logoMixin;
