export const API_SERVICE = {
  activitylog: 'activitylog',
  charis: 'charis',
  config: 'config',
  delivery: 'delivery',
  iam: 'iam',
  ordering: 'ordering',
  shipping: 'shipping',
};

export default API_SERVICE;
