export const state = () => ({
  fetchingConfigEntries: false,
  configEntries: [],
  configKeyToValueMap: {},
});

export const mutations = {
  setFetchingConfigEntries (state, isLoading = false) {
    state.fetchingConfigEntries = isLoading;
  },
  setConfigEntries (state, entries = []) {
    state.configEntries = entries;
    state.configKeyToValueMap = entries.reduce((configKeyToValueMap, entry) => {
      const { key, value } = entry;
      if (key in configKeyToValueMap === false) {
        configKeyToValueMap[key] = value;
      }
      return configKeyToValueMap;
    }, {});
  },
};

export const getters = {
  fetchingConfigEntries: (state) => {
    return state.fetchingConfigEntries;
  },
  configEntries: (state) => {
    return state.configEntries;
  },
  configKeyToValueMap: (state) => {
    return state.configKeyToValueMap;
  },
};

export const actions = {
  async fetchConfigEntries () {
    this.commit('config/setFetchingConfigEntries', true);
    try {
      const { data } = await this.$api.config.list();
      this.commit('config/setConfigEntries', data?.payload?.entries || []);
    } catch (error) {
      if (error) {
        const { data = {}, status } = error;
        const { msg } = data;
        if (msg && status && this._vm) {
          this._vm.handleStoreServerError({ msg, status }, this);
        }
      }
    } finally {
      this.commit('config/setFetchingConfigEntries', false);
    }
  },
};
