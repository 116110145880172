
import { mapActions } from 'vuex';
import FreshChatScript from '~/components/chat/FreshChatScript';
import SelectCountryModal from '~/components/modals/SelectCountryModal';

export default {
  components: {
    FreshChatScript,
    SelectCountryModal,
  },
  middleware: ['fetch-common-data', 'fetch-page-data'],
  head () {
    return {
      link: [
        {
          rel: 'canonical',
          href: 'https://heroshe.com' + this.$route.path
        }
      ]
    };
  },
  watch: {
    '$route.path' () {
      this.closePageSidebar();
    },
  },
  created () {
    const configEntriesInStoreIsEmpty = this.configEntries.length === 0;
    if (configEntriesInStoreIsEmpty) {
      this.fetchConfigEntries();
    }
  },
  methods: {
    ...mapActions({
      closePageSidebar: 'page/closePageSidebar',
      fetchConfigEntries: 'config/fetchConfigEntries',
    }),
  },
};
