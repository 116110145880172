export const state = () => ({
  chinaAddressModal: false,
  delawareAddressChangedModal: false,
  oceanShippingCountDownModal: false,
  subscriptionDeal: false,
  blackFriday: false,
});

export const mutations = {
  /**
     * Set Delaware Address Changed Modal state
     * @param state
     * @param delawareAddressChangedModal
     * @param cache
     */
  setDelawareAddressChangedModal (state, { delawareAddressChangedModal, cache = true }) {
    state.delawareAddressChangedModal = delawareAddressChangedModal;
    if (cache) {
      this.$cookies.set('delawareAddressChangedModal', delawareAddressChangedModal);
    }
  },
  setPromotion (state, { promotion, cache = true }) {
    const { key, value } = promotion;
    state[key] = value;
    if (cache) {
      this.$cookies.set('promotions', { ...state, [key]: value });
    }
  },
  setPromotions (state, promotions) {
    Object.entries(promotions).forEach(([key, value]) => {
      state[key] = value;
    });
  },
};

export const getters = {
  delawareAddressChangedModal: (state) => {
    return state.delawareAddressChangedModal;
  },
  chinaAddressModal: (state) => {
    return state.chinaAddressModal;
  },
  oceanShippingCountDownModal: (state) => {
    return state.oceanShippingCountDownModal;
  },
  subscriptionDeal: (state) => {
    return state.subscriptionDeal;
  },
  blackFriday: (state) => {
    return state.blackFriday;
  },
};

export const actions = {
  /**
     * Toggle Delaware Address Changed Modal
     * @param commit
     * @param delawareAddressChangedModal
     */
  setDelawareAddressChangedModal ({ commit }, delawareAddressChangedModal) {
    commit('setDelawareAddressChangedModal', delawareAddressChangedModal);
  },
  /**
     * Update All Promotions
     * @param commit
     * @param promotions
     */
  setPromotions ({ commit }, promotions) {
    commit('setPromotions', promotions);
  },
  /**
     * Update Single Promotion
     * @param commit
     * @param promotions
     */
  setPromotion ({ commit }, promotion) {
    commit('setPromotion', promotion);
  },
};
