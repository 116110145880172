const statesCities = {
  states: {
    Abia: [],
    Adamawa: [],
    'Akwa Ibom': [
      'Abak',
      'Eastern Obolo',
      'Eket',
      'Esit-Eket',
      'Essien Udim',
      'Etim-Ekpo',
      'Etinan',
      'Ibeno',
      'Ibesikpo-Asutan',
      'Ibiono-Ibom',
      'Ika',
      'Ikono',
      'Ikot Abasi',
      'Ikot Ekpene',
      'Ini',
      'Itu',
      'Mbo',
      'Mkpat-Enin',
      'Nsit-Atai',
      'Nsit-Ibom',
      'Nsit-Ubium',
      'Obot-Akara',
      'Okobo',
      'Onna',
      'Oron',
      'Oruk Anam',
      'Ukanafun',
      'Udung-Uko',
      'Uruan',
      'Urue-Offong/Oruko',
      'Uyo',
    ],
    Anambra: [],
    Bauchi: [],
    Bayelsa: [],
    Benue: [
      'Ado',
      'Agatu',
      'Apa',
      'Buruku',
      'Gboko',
      'Guma',
      'Gwer East',
      'Gwer West',
      'Katsina-Ala',
      'Konshisha',
      'Kwande',
      'Logo',
      'Makurdi',
      'Obi',
      'Ogbadibo',
      'Ohimini',
      'Oju',
      'Okpokwu',
      'Otukpo',
      'Tarka',
      'Ukum',
      'Ushongo',
      'Vandeikya',
    ],
    Borno: [],
    'Cross River': [
      'Abi',
      'Akamkpa',
      'Akpabuyo',
      'Bekwarra',
      'Bakassi',
      'Biase',
      'Boki',
      'Calabar Municipal',
      'Calabar South',
      'Etung',
      'Ikom',
      'Obanliku',
      'Obubra',
      'Obudu',
      'Odukpani',
      'Ogoja',
      'Yakuur',
      'Yala',
    ],
    Delta: [
      'Aniocha North',
      'Aniocha South',
      'Burutu',
      'Bomadi',
      'Ethiope East',
      'Ethiope West',
      'Ika North',
      'Ika South',
      'Isoko North',
      'Isoko South',
      'Ndokwa East',
      'Ndokwa West',
      'Oshimili North',
      'Oshimili South',
      'Okpe',
      'Patani',
      'Sapele',
      'Udu',
      'Ughelli North',
      'Ughelli South',
      'Ukwuani',
      'Uvwie',
      'Warri North',
      'Warri South',
      'Warri South West',
    ],
    Ebonyi: [],
    Edo: [
      'Akoko-Edo',
      'Egor',
      'Esan Central',
      'Esan North-East',
      'Esan South-East',
      'Esan West',
      'Etsako Central',
      'Etsako East',
      'Etsako West',
      'Igueben',
      'Ikpoba-Okha',
      'Oredo',
      'Orhionmwon',
      'Ovia North-East',
      'Ovia South-West',
      'Owan East',
      'Owan West',
      'Uhunmwonde',
    ],
    Ekiti: [],
    Enugu: [
      'Aninri',
      'Awgu',
      'Enugu East',
      'Enugu North',
      'Enugu South',
      'Ezeagu',
      'Igbo Etiti',
      'Igbo Eze North',
      'Igbo Eze South',
      'Isi Uzo',
      'Nkanu East',
      'Nkanu West',
      'Nsukka',
      'Oji River',
      'Udenu',
      'Udi',
      'Uzo-Uwani',
    ],
    FCT: ['Abaji', 'AMAC', 'Bwari', 'Guzape', 'Gwagwalada', 'Kuje', 'Kwali'],
    Gombe: [],
    Imo: [
      'Aboh Mbaise',
      'Ahiazu Mbaise',
      'Ehime Mbano',
      'Ezinihitte Mbaise',
      'Ideato North',
      'Ideato South',
      'Ihitte/Uboma',
      'Ikeduru',
      'Isiala Mbano',
      'Isu',
      'Mbaitoli',
      'Ngor Okpala',
      'Njaba',
      'Nkwerre',
      'Nwangele',
      'Obowo',
      'Oguta',
      'Ohaji/Egbema',
      'Okigwe',
      'Onuimo',
      'Orlu',
      'Orsu',
      'Oru East',
      'Oru West',
      'Owerri Municipal',
      'Owerri North',
      'Owerri West',
      'Njaba South',
    ],
    Jigawa: [],
    Kaduna: [],
    Kano: [],
    Katsina: [
      'Bakori',
      'Batagarawa',
      'Batsari',
      'Baure',
      'Bindawa',
      'Charanchi',
      'Dan Musa',
      'Dandume',
      'Danja',
      'Daura',
      'Dutsi',
      'Dutsin-Ma',
      'Faskari',
      'Funtua',
      'Ingawa',
      'Jibia',
      'Kafur',
      'Kaita',
      'Kankara',
      'Kankia',
      'Katsina',
      'Kurfi',
      'Kusada',
      "Mai'Adua",
      'Malumfashi',
      'Mani',
      'Mashi',
      'Matazu',
      'Musawa',
      'Rimi',
      'Sabuwa',
      'Safana',
      'Sandamu',
      'Zango',
    ],
    Kebbi: [],
    Kogi: [
      'Adavi',
      'Ajaokuta',
      'Ankpa',
      'Bassa',
      'Dekina',
      'Ibaji',
      'Idah',
      'Igalamela-Odolu',
      'Ijumu',
      'Kabba/Bunu',
      'Koton Karfe',
      'Lokoja',
      'Mopa-Muro',
      'Ofu',
      'Ogori/Magongo',
      'Okehi',
      'Okene',
      'Olamaboro',
      'Omala',
      'Yagba East',
      'Yagba West',
    ],
    Kwara: [
      'Asa',
      'Baruten',
      'Edu',
      'Ekiti',
      'Ifelodun',
      'Ilorin East',
      'Ilorin South',
      'Ilorin West',
      'Irepodun',
      'Isin',
      'Kaiama',
      'Moro',
      'Offa',
      'Oke Ero',
      'Oyun',
      'Pategi',
    ],
    Lagos: [
      'Agbado/Oke-Odo',
      'Agboyi-Ketu',
      'Agege',
      'Ajeromi',
      'Alimosho',
      'Amuwo Odofin',
      'Apapa Iganmu',
      'Apapa',
      'Ayobo Ipaja',
      'Badagry West',
      'Badagry',
      'Bariga',
      'Coker Aguda',
      'Egbe-Idimu',
      'Ejigbo',
      'Epe',
      'Eredo',
      'Eti Osa East',
      'Eti Osa',
      'Iba',
      'Ibeju Lekki',
      'Ifako Ijaye',
      'Ifelodun',
      'Igando-Ikotun',
      'Igbobo-Baiyeku',
      'Ijede',
      'Ikeja',
      'Ikorodu North',
      'Ikorodu West',
      'Ikorodu',
      'Ikosi Ejinrin',
      'Ikosi-Isheri',
      'Ikoyi Obalande',
      'Imota',
      'Iru- Victoria Island',
      'Isolo',
      'Itire Ikate',
      'Kosofe',
      'Lagos Island East',
      'Lagos Island',
      'Lagos Mainland',
      'Lekki',
      'Mosan Okunola',
      'Mushin',
      'Odi - Olowo',
      'Ojo',
      'Ojodu',
      'Ojokoro',
      'Olorunda',
      'Onigbongbo',
      'Oriade',
      'Orile Agege',
      'Oshodi Isolo',
      'Oto Awori',
      'Somolu',
      'Surulere',
      'Yaba',
    ],
    Nasarawa: [],
    Niger: [],
    Ogun: [
      'Abeokuta North',
      'Abeokuta South',
      'Ado-Odo/Ota',
      'Ewekoro',
      'Ifo',
      'Ijebu East',
      'Ijebu North',
      'Ijebu North East',
      'Ijebu Ode',
      'Ikenne',
      'Imeko Afon',
      'Ipokia',
      'Obafemi Owode',
      'Odogbolu',
      'Odeda',
      'Ogun Waterside',
      'Remo North',
      'Sagamu',
      'Yewa North',
      'Yewa South',
    ],
    Ondo: [
      'Akoko North-East',
      'Akoko North-West',
      'Akoko South-East',
      'Akoko South-West',
      'Akure North',
      'Akure South',
      'Ese Odo',
      'Idanre',
      'Ifedore ',
      'Ilaje ',
      'Ile Oluji/Okeigbo',
      'Irele',
      'Odigbo',
      'Okitipupa',
      'Ondo East',
      'Ondo West',
      'Ose',
      'Owo',
    ],
    Osun: [],
    Oyo: [
      'Afijio',
      'Akinyele',
      'Atiba',
      'Atisbo',
      'Egbeda',
      'Ibadan North',
      'Ibadan North-East',
      'Ibadan North-West',
      'Ibadan South-East',
      'Ibadan South-West',
      'Ibarapa Central',
      'Ibarapa East',
      'Ibarapa North',
      'Ido',
      'Irepo',
      'Iseyin',
      'Itesiwaju',
      'Iwajowa',
      'Kajola',
      'Lagelu',
      'Ogbomosho North',
      'Ogbomosho South',
      'Ogo Oluwa',
      'Olorunsogo',
      'Oluyole',
      'Ona Ara',
      'Orelope',
      'Ori Ire',
      'Oyo East',
      'Oyo West',
      'Saki East',
      'Saki West',
      'Surulere',
    ],
    Plateau: [],
    Rivers: [
      'Abua-Odual',
      'Ahoada',
      'Ahoada East',
      'Ahoada West',
      'Aku',
      'Akuku Toru',
      'Andoni',
      'Asari-Toru',
      'Bonny',
      'Degema',
      'Eleme',
      'Emuohua',
      'Etche',
      'Gokana',
      'Ikwerre',
      'Khana',
      'Obio-Akpor',
      'Ogba-Egbema-Ndoni',
      'Ogoni',
      'Ogu-Bolo',
      'Ohaji-Egbema Ndoni',
      'Okrika',
      'Opobo-Nkoro',
      'Oyigbo',
      'Port Harcourt',
      'Omumma',
      'Tai',
    ],
    Sokoto: [],
    Taraba: [],
    Yobe: [
      'Bade',
      'Bursari',
      'Damaturu',
      'Geidam',
      'Gujba',
      'Gulani',
      'Fika',
      'Fune',
      'Jakusko',
      'Karasuwa',
      'Machina',
      'Nangere',
      'Nguru',
      'Potiskum',
      'Tarmuwa',
      'Yunusari',
      'Yusufari',
    ],
    Zamfara: [],
  },
};

export default statesCities;
